import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import {numberFormat} from "../../../../../../services/helpers";
import { selectDefTableSize } from '../../../../../../selectors/screenSelectors';
import CheckboxFilter from "../../../../../common/table/Checkbox";
import DatePicker from '../../../../../common/table/DatePicker';
import NumberInput from '../../../../../common/table/NumberInput';
import createSelect from '../../../../../common/table/createSelect';
import StringInput from '../../../../../common/table/StringInput';

const typeAccountingList = [
    {
        value: 1, label: 'Кошти',
    },
    {
        value: 2, label: 'Літри',
    }
];

class RegisterTable extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        return (
            <div className={`table-main-wrap table-main-wrap_register ${this.props.propsGetDataStatus == 'pending' ? 'table-main-wrap_loading' : '' }`}>
                <div className="table-header">Інформація про реєстри</div>
                <ReactTable
                    getTheadFilterThProps={(rowInfo) => {
                        return {
                            style: {
                                overflow: 'visible',
                                textAlign: 'left'
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.props.propsRowClick({
                                        id: rowInfo.row._original.ID,
                                        loan: rowInfo.row._original.Loan,
                                        index: rowInfo.index
                                    });
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.props.index ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.props.index ? 'white' : 'black',
                                    cursor: 'pointer'
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={this.props.propsArraySort}
                    columns={[
                        {
                            Header: "",
                            filterable: false,
                            sortable: false,
                            width: 30,
                            id: "IsValid&IsCovered",
                            accessor: item => {
                                return item.Loan && item.BalanceCoverageAmount > 0 ? (
                                    <i className="red circle" />
                                ) : ''
                            }
                        },
                        {
                            Filter: StringInput,
                            Header: "Компанія",
                            accessor: "Company.Name",
                            id: "Company/Name"
                        },
                        {
                            Filter: DatePicker,
                            Header: "Дата реєстрації",
                            minWidth: 170,
                            id: "RegisterDate",
                            accessor: item => {
                                return new Date(item.RegisterDate).toLocaleString()
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 150,
                            Header: "Кількість",
                            className: "rightAlign",
                            id: "Amount",
                            accessor: item => {
                                return numberFormat(item.Amount)
                            },
                        },
                        {
                            Filter: createSelect({ options: typeAccountingList, placeholder: 'Виберіть' }),
                            Header: "Тип обліку",
                            minWidth: 150,
                            id: "Company/TypeRegisterID",
                            className: "rightAlign",
                            accessor: item => {
                                return item.Company.TypeRegister.RegisterName
                            }
                        },
                        {
                            Filter: createSelect({ options: this.props.data.fuelList, placeholder: 'Виберіть' }),
                            Header: "Вид палива",
                            id: "TypeFuelID",
                            minWidth: 150,
                            className: "rightAlign",
                            accessor: item => {
                                return item.TypeFuel === null ? '-------------' : item.TypeFuel.FuelName
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 150,
                            Header: "Ціна продажу, грн./л.",
                            id: "Price",
                            className: "rightAlign",
                            accessor: item => {
                                return !item.Price ? '' : numberFormat(item.Price)
                            }
                        },
                        {
                            Filter: CheckboxFilter,
                            Header: "Заблоковано",
                            id: "Closed",
                            accessor: item => {
                                return <input type="checkbox" checked={item.Closed ? true : false} readOnly/>
                            }
                        },
                        {
                            Filter: CheckboxFilter,
                            Header: "Кредит",
                            id: "Loan",
                            accessor: item => {
                                return <input type="checkbox" checked={item.Loan ? true : false} readOnly/>
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 150,
                            Header: "Сума заборгованості",
                            id: "RegisterAmount",
                            className: "rightAlign",
                            accessor: item => {
                                if (!item.Loan) return '';
                                return !item.RegisterAmount ? '' : numberFormat(item.RegisterAmount)
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 150,
                            Header: "Сума погашення",
                            id: "CoverageAmount",
                            className: "rightAlign",
                            accessor: item => {
                                if (!item.Loan) return '';
                                return !item.CoverageAmount ? '' : numberFormat(item.CoverageAmount)
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 150,
                            Header: "Залишок заборгованості",
                            id: "BalanceCoverageAmount",
                            className: "rightAlign",
                            accessor: item => {
                                if (!item.Loan) return '';
                                return !item.BalanceCoverageAmount ? '' : numberFormat(item.BalanceCoverageAmount)
                            }
                        }
                    ]}
                    defaultPageSize={this.props.defaultPageSize}
                    className=" -highlight"
                    pageSize={this.props.pageSize}
                    page={this.props.page}
                    onPageSizeChange={this.props.handleChangePageSize}
                    onPageChange={this.props.handleChangePage}
                    manual={true}
                    filterable
                    sortable
                    multiSort
                    onFilteredChange={this.props.handleOnFilterChange}
                    onSortedChange={this.props.handleOnSortChange}
                    filtered={this.props.filtered}
                    sorted={this.props.sorted}
                    pages={Math.ceil(this.props.count / this.props.pageSize)}
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                />
                <div className="loading"></div>
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        defaultPageSize: selectDefTableSize(state),
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
       dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterTable);
