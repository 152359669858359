import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from "../../../../../../actions/modals";
import api from '../../../../../../api/index';
import Spiner from "../../../../../common/Spiner";

import pluralize from "../../../../../../helpers/pluralize";

class CouponDeleteAllModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            callback: () => {},
        };
    }

    deleteCoupon = async (coupon) => {
        let reqData = {
            ID: coupon.ID
        };

        return api.apiDeleteCoupon(reqData)
    }

    handleDelete = () => {
        this.setState({ loading: true });
        Promise.all(this.props.propsData.arraySort.map(this.deleteCoupon)).then(() => {
            this.setState({ loading: false });
            this.props.dispatchHideModal();
            this.props.dispatchShowModal('INFO_MODAL', { title: 'Видалення талонів', description: 'Талони були успішно видалено', type: true });
            this.props.propsData.callback();
        }).catch(() => {
            this.setState({ loading: false });
            this.props.dispatchHideModal();
            this.props.dispatchShowModal('INFO_MODAL', { title: 'Видалення талонів', description: 'Талони не були видалено. Зверніться до адміністратора', type: false });
        });
    };

    render(){
        const { arraySort } = this.props.propsData;
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times" />
                    </button>
                    <h5 className="modal-title">Видалити талони</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                {`Ви дійсно бажаєте видалити ${arraySort.length} ${pluralize({ plurals: ['талон', 'талони', 'талонів'], count: arraySort.length })}?`}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleDelete}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Так</span>
                                        : <span>Видаляємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(null, mapDispatchToProps)(CouponDeleteAllModal);
