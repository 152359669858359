import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { numberFormat } from "../../../../../../services/helpers";
import { selectDefTableSize } from '../../../../../../selectors/screenSelectors';
import createSelect from '../../../../../common/table/createSelect';
import NumberInput from '../../../../../common/table/NumberInput';
import StringInput from '../../../../../common/table/StringInput';

const typeRegister = [
    { value: '1', label: 'Кошти' },
    { value: '2', label: 'Літри' },
];

class Table extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        const { defaultPageSize } = this.props;
        return (
            <div className={`table-main-wrap table-main-wrap_register ${this.props.propsGetDataStatus == 'pending' ? 'table-main-wrap_loading' : '' }`}>
                <div className="table-header">Інформація про рух по компаніям</div>
                <ReactTable
                    getTheadFilterThProps={(rowInfo) => {
                        return {
                            style: {
                                overflow: 'visible',
                                textAlign: 'left'
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.props.propsRowClick({
                                        id: rowInfo.row._original.ID,
                                        index: rowInfo.index
                                    });
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.props.index ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.props.index ? 'white' : 'black',
                                    cursor: 'pointer'
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={this.props.propsArraySort}
                    columns={[
                        {
                            Filter: StringInput,
                            id: "Company/Name",
                            Header: "Компанія",
                            accessor: "Company.Name"
                        },
                        {
                            sortable: false,
                            Filter: createSelect({ options: typeRegister, placeholder: 'Виберіть'}),
                            Header: "Тип обліку",
                            minWidth: 150,
                            id: "Company/TypeRegisterID",
                            accessor: "Company.TypeRegister.RegisterName",
                        },
                        {
                            Filter: createSelect({ options: this.props.data.fuelList, placeholder: 'Виберіть' }),
                            Header: "Вид палива",
                            minWidth: 150,
                            id: "TypeFuelID",
                            accessor: item => {
                                return item.TypeFuel === null ? '-------------' : item.TypeFuel.FuelName
                            }
                        },
                        {
                            Filter: NumberInput,
                            Header: "Сума реєстру",
                            id: "RegisterAmount",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.RegisterAmount)
                            }
                        },
                        {
                            Filter: NumberInput,
                            Header: "Сума використаного",
                            id: "UsedAmount",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.UsedAmount)
                            }
                        },
                        {
                            filterable: false,
                            sortable: false,
                            Header: "Сума залишку",
                            id: "Limit",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.RegisterAmount - item.UsedAmount)
                            }
                        },
                    ]}
                    defaultPageSize={defaultPageSize}
                    className=" -highlight"
                    pageSize={this.props.pageSize}
                    page={this.props.page}
                    onPageSizeChange={this.props.handleChangePageSize}
                    onPageChange={this.props.handleChangePage}
                    manual={true}
                    filterable
                    sortable
                    multiSort
                    onFilteredChange={this.props.handleOnFilterChange}
                    onSortedChange={this.props.handleOnSortChange}
                    filtered={this.props.filtered}
                    sorted={this.props.sorted}
                    pages={Math.ceil(this.props.count / this.props.pageSize)}
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                />

                <div className="loading"></div>
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        defaultPageSize: selectDefTableSize(state),
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
       dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
