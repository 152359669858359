import React from 'react';
import { Modal } from 'react-bootstrap';
import DateTimePicker from "react-datetime-picker";

const DateModal = ({ hideModal, show, from, to, applyDates }) => {
    return (
        <Modal show={show} onHide={hideModal}>
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={hideModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Виберіть дати</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="modal__row">
                            <h5>Від</h5>
                            <div>
                                <DateTimePicker
                                    className="z-index-3"
                                    locale="uk-UK"
                                    onChange={(from) => applyDates({ from, to })}
                                    value={from}
                                    maxDate={to}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="modal__row">
                            <h5>До</h5>
                            <div>
                                <DateTimePicker
                                    locale="uk-UK"
                                    onChange={(to) => applyDates({ from, to })}
                                    value={to}
                                    minDate={from}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                onClick={hideModal}
                                type="button"
                                className="btn btn-primary"
                            >
                                Закрити
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        
    )
}

export default DateModal;