import React, { Component } from 'react';
import {normalizeNumberMaxDecimals, renderError} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import api from "../../../../../../api";
import {hideModal, showModal} from "../../../../../../actions/modals";
import {connect} from "react-redux";
import Select from "react-select";
import Spiner from "../../../../../common/Spiner";
import {toCompanyList, toFuelList} from "../../../../../../helpers/toLists";

class PriceEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            company: '',
            fuelType: '',
            price: '',
            data: {
                fuelList: [],
                companyList: [],
            },
            validation: [],
            callback: () => {},
        };
    }

    async componentDidMount(){
        const [companyList, fuelList] = await Promise.all([
            api.apiGetCompanyList().then(toCompanyList),
            api.apiGetFuelList().then(toFuelList),
        ]);

        this.setState({
            data: {
                fuelList,
                companyList,
            },
            ID: this.props.propsData.ID,
            company: {
                value: this.props.propsData.Company.ID, label: this.props.propsData.Company.Name
            },
            fuelType: this.props.propsData.TypeFuel === null ? '' : {
                value: this.props.propsData.TypeFuel.ID, label: this.props.propsData.TypeFuel.FuelName
            },
            price: this.props.propsData.Price,
            callback: this.props.propsData.callback
        });
    }

    handleCompany = (selectedOption) => {
        this.setState({
            company: selectedOption
        });
    };

    handleFuelType = (selectedOption) => {
        this.setState({
            fuelType: selectedOption
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'company', name: 'Назва компанії', type: 'EMPTY', value: this.state.company },
            { id: 'type', name: 'Тип палива', type: 'EMPTY', value: this.state.fuelType },
            { id: 'price', name: 'Ціна продажу', type: 'EMPTY', value: this.state.price },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                ID: +this.state.ID,
                CompanyID: +this.state.company.value,
                Price: +this.state.price,
                TypeFuelID: this.state.fuelType.value
            };

            api.apiEditCompanyPrice(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 204){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування ціни', description: 'Ціна була успішно змінена', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування ціни', description: 'Ціна не була змінена. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування ціни', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати ціну</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Назва компанії</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть компанію"
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={this.state.data.companyList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Тип палива</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть тип палива"
                                        value={this.state.fuelType}
                                        onChange={this.handleFuelType}
                                        options={this.state.data.fuelList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('type', this.state.validation) }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ціна продажу, грн./л.</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть ціну"
                                        type="text"
                                        className="form-control"
                                        name="price"
                                        data-type="number"

                                        value={this.state.price || ''}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('price', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceEditModal);
