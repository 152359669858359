import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import {renderError} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import api from "../../../../../../api";
import { hideModal, showModal } from "../../../../../../actions/modals";
import Spiner from "../../../../../common/Spiner";
import {toEmployeeList, toFuelList} from "../../../../../../helpers/toLists";

class RegisterAuditCreateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                fuelList: [],
                employeeList: []
            },

            fuelType: '',
            employee: '',

            callback: () => {},

            validation: []
        };
    }

    async componentDidMount(){

        const [employeeList, fuelList] = await Promise.all([
            api.apiGetEmployeeData().then(toEmployeeList),
            api.apiGetFuelList().then(toFuelList),
        ]);

        this.setState({
            data: {
                employeeList,
                fuelList
            },
            callback: this.props.propsData.callback
        });
    }

    handleEmployee = (selectedOption) => {
        this.setState({
            employee: selectedOption
        });
    };

    handleFuelType = (selectedOption) => {
        this.setState({
            fuelType: selectedOption
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'fuelType', name: 'fuel type', type: 'EMPTY', value: this.state.fuelType },
            { id: 'employee', name: 'employee', type: 'EMPTY', value: this.state.employee },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                AuditFuelID: +this.state.fuelType.value,
                EmployeeID: +this.state.employee.value
            };

            api.apiCreateRegisterAudit(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 201){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити запис', description: 'Запис був успішно створений', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити запис', description: 'Запис не був створений. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити запис', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Створити запис</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Тип палива</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть тип палива"
                                        value={this.state.fuelType}
                                        onChange={this.handleFuelType}
                                        options={this.state.data.fuelList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('fuelType', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Працівник</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть працівника"
                                        value={this.state.employee}
                                        onChange={this.handleEmployee}
                                        options={this.state.data.employeeList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('employee', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAuditCreateModal);
