import React from 'react';


const InfoModal = ({ closeModal, propsData }) => (
  <div className="modal-content modal-content-custom">
    <div className="modal-header">
      <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-hidden="true">
        <i className="fa fa-times" />
      </button>
      <h5 className="modal-title">{propsData.title}</h5>
    </div>
    <div className="modal-body">
      {
        propsData.type === null ?  <div className="loading active" /> :
        <div className="result-icon">
            {
                propsData.type ? (
                    <div className="icon-border success">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                             preserveAspectRatio="none">
                            <circle fill="red" cx="8" cy="8" r="6.215" transform="rotate(90 8 8)" />
                        </svg>
                        <i className="icon fa fa-check" />
                    </div>
                ) : (
                    <div className="icon-border error">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                             preserveAspectRatio="none">
                            <circle fill="green" cx="8" cy="8" r="6.215" transform="rotate(90 8 8)" />
                        </svg>
                        <i className="icon fa fa-times" aria-hidden="true" />
                    </div>
                )
            }
        </div>
      }
      <h5 className={`modal-result text-center ${propsData.type === null ? 'modal-loading' : ''}`}>{propsData.description}</h5>
      {
        propsData.type === null ? null :
        <div className="df js-center">
          <button onClick={closeModal} type="button" className="btn btn-light">Ok</button>
        </div>
      }
    </div>
  </div>
)

export default InfoModal;
