import React, { Component } from 'react';
import { connect } from 'react-redux'

import Table from './items/Table';
import Wrapper from '../index';
import Sidebar from '../cod/Sidebar';
import Station from '../elements/Station';

import api from '../../../api/index';
import { showModal } from "../../../actions/modals";
import {toPascalCase} from "../../../services/helpers";
import getCount from "../../../helpers/getCount";
import {toFuelList} from "../../../helpers/toLists";
import sortConverter from "../../../helpers/sortConverter";
import { selectNumberConverter, numberConverter, createFilter } from "../../../helpers/filterConverters";
import { setFilterData } from '../../../actions/filtersReducers';

const convertersMap = {
    TypeFuelID: selectNumberConverter,
    Price: numberConverter,
    'BarrelBalance/BalanceAmountY': numberConverter,
    'BarrelBalance/BalanceAmountZ': numberConverter,
    'BarrelBalance.BalanceAmount': numberConverter,
    LowCapacity: numberConverter,
    WarningCapacity: numberConverter,
}

class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: {
                fuelList: [],
            },
            filtered: [],
            sorted: [],
            count: 0,
            pageSize: 10,
            page: 0,
            rowID: null,
            index: null,
            getDataStatus: 'success',
            array: [],
            arraySort: []
        };
    }

    static filterType = 'BARREL'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.filtered, Index.filterType));
    }

    async componentDidMount(){
        const fuelList = await api.apiGetFuelList().then(toFuelList);
        this.setState({
            filtered: this.props.filtersReducer[Index.filterType] || this.state.filtered,
            data: {
                fuelList
            }
        }, this.handleGetData)
    }

    handleChangePageSize = (pageSize) => {
        this.setState({ page: 0, pageSize }, () => this.handleGetData())
    }

    handleChangePage = (page) => {
        this.setState({ page }, () => this.handleGetData())
    }

    handleGetData = async () => {
        this.setState({
            getDataStatus: 'pending'
        });
        const data = {
            filter: createFilter(this.state.filtered, convertersMap),
            order: sortConverter(this.state.sorted),
            top: this.state.pageSize,
            skip: this.state.pageSize * this.state.page
        };

        let res = await api.apiGetBarrelData(data);


        this.setState({
            rowID: null,
            index: null,
            array: toPascalCase(res.data.value),
            arraySort: toPascalCase(res.data.value),
            getDataStatus: 'success',
            count: getCount(res)
        });

    };

    handleRowClick = (data) => {
        this.setState({
            rowID: data.id,
            index: data.index
        });
    };

    handleEdit = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleGetData;
        this.props.dispatchShowModal('BARREL_EDIT_MODAL', tmpObj);
    };

    findObject = () => {
        let tmpObj = {};
        for(let i = 0; i < this.state.array.length; i++){
            if(this.state.rowID == this.state.array[i].ID){
                tmpObj = Object.assign({}, this.state.array[i]);
            }
        }
        return tmpObj;
    };

    handleOnFilterChange = (filtered = []) => {
        this.setState({ filtered }, this.handleGetData)
    }

    handleOnSortChange = (sorted = []) => {
        this.setState(
            { sorted },
            () => {
                this.handleGetData();
            }
        );
    }

    handleClearFilter = async () => {
        this.setState({
            page: 0,
            filtered: []
        }, () => {
            this.handleGetData();
        });
    };

    render(){
        return (
            <Wrapper>
                <div className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <Station />
                        </div>
                        <div className="col-lg-12">
                            <Table
                                handleChangePageSize={this.handleChangePageSize}
                                handleChangePage={this.handleChangePage}
                                count={this.state.count}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                propsRowID={this.state.rowID}
                                propsRowClick={this.handleRowClick}
                                propsArraySort={this.state.arraySort}
                                propsGetDataStatus={this.state.getDataStatus}
                                index={this.state.index}
                                handleOnFilterChange={this.handleOnFilterChange}
                                handleOnSortChange={this.handleOnSortChange}
                                filtered={this.state.filtered}
                                sorted={this.state.sorted}
                                data={this.state.data}
                            />
                        </div>
                    </div>
                    <Sidebar
                        propsOnEdit={() => this.handleEdit()}
                        propsDisableEdit={ this.state.rowID == null ? true : false }
                        showNavigation={false}
                        onClear={this.handleClearFilter}
                    />
                </div>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
