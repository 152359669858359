import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import {normalizeNumberMaxDecimals, renderError} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import api from "../../../../../../api";
import { hideModal, showModal } from "../../../../../../actions/modals";
import Spiner from "../../../../../common/Spiner";
import {toCompanyList, toFuelList} from "../../../../../../helpers/toLists";

class CompanyMovementCreateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                companyList: [],
                fuelList: []
            },

            company: '',
            fuelType: '',
            registerAmount: '',
            usedAmount: '',

            callback: () => {},

            validation: []
        };
    }

    async componentDidMount(){

        const [companyList, fuelList] = await Promise.all([
            api.apiGetCompanyList().then(toCompanyList),
            api.apiGetFuelList().then(toFuelList),
        ]);


        this.setState({
            data: {
                companyList,
                fuelList,
            },
            callback: this.props.propsData.callback
        });
    }

    handleFuelType = (selectedOption) => {
        this.setState({
            fuelType: selectedOption
        });
    };

    handleCompany = (selectedOption) => {
        this.setState({
            company: selectedOption
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'company', name: 'company', type: 'EMPTY', value: this.state.company },
            { id: 'registerAmount', name: 'register amount', type: 'EMPTY', value: this.state.registerAmount },
            { id: 'usedAmount', name: 'used amount', type: 'EMPTY', value: this.state.usedAmount },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                CompanyID: +this.state.company.value,
                TypeFuelID: +this.state.fuelType.value,
                RegisterAmount: +this.state.registerAmount,
                BalanceAmount: +this.state.registerAmount,
                UsedAmount: +this.state.usedAmount,
            };

            api.apiCreateCompanyMovement(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 201){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити запис', description: 'Запис був успішно створений', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити запис', description: 'Запис не був створений. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити компанію', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });

    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Створити запис</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={this.state.data.companyList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Вид палива</h5>
                                <div>
                                    <Select
                                        value={this.state.fuelType}
                                        onChange={this.handleFuelType}
                                        options={this.state.data.fuelList}
                                    />
                                    <div className="form-group-error">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Сума реєстру</h5>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="registerAmount"
                                        data-type="number"

                                        value={this.state.registerAmount}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('registerAmount', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Сума використаного</h5>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="usedAmount"
                                        data-type="number"

                                        value={this.state.usedAmount}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('usedAmount', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMovementCreateModal);
