import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from "../../../../../../actions/modals";
import api from '../../../../../../api/index';
import Spiner from "../../../../../common/Spiner";
import {numberFormat} from "../../../../../../services/helpers";

class CouponDeleteModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            ID: '',
            name: '',

            callback: () => {},
        };
    }

    componentDidMount(){
        if(typeof this.props.propsData != 'undefined'){
            const { ID, Capacity, Price, TypeFuel } = this.props.propsData;
            const nameArray = [];
            if (TypeFuel) {
                nameArray.push(`Тип Палива : ${TypeFuel.FuelName}`)
            }
            if (Capacity) {
                nameArray.push(`Номінал: ${Capacity}`)
            }
            if (Price) {
                nameArray.push(`Ціна: ${numberFormat(Price)}`)
            }
            this.setState({
                ID,
                name: nameArray.join(', '),
                callback: this.props.propsData.callback
            })
        }
    }

    handleDeleteAll = () => {
        this.props.closeModal();
        const tmpObj = {
            callback: this.props.propsData.callback,
            arraySort: this.props.propsData.arraySort,
        };
        this.props.dispatchShowModal('COUPON_DELETE_ALL_MODAL', tmpObj);
    }

    handleDelete = () => {

        let reqData = {
            ID: this.state.ID
        };

        this.setState({ loading: true });
        api.apiDeleteCoupon(reqData).then(res => {
            this.setState({ loading: false });
            if(res.status == 200){
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Видалення талона', description: 'Талон "' + this.state.name + '" був успішно видалено', type: true })
                this.state.callback();
            } else {
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Видалення талона', description: 'Талон "' + this.state.name + '" не був видалено. Зверніться до адміністратора', type: false })
            }
        }).catch(error => {
            this.setState({ loading: false });
            this.props.dispatchHideModal();
            this.props.dispatchShowModal('INFO_MODAL', { title: 'Видалення талона', description: error.response.data.message, type: false });
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Видалити талон</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                Ви дійсно бажаєте видалити талон "{ this.state.name }"
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleDelete}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Так</span>
                                        : <span>Видаляємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                            <button
                                type="button"
                                onClick={this.handleDeleteAll}
                                className="btn btn-secondary"
                                style={{
                                    position: "absolute",
                                    left: '5px'
                                }}
                            >
                                Видалити всі
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponDeleteModal);
