import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import './style.css';

import AppMain from "./components/index";
import store from './store/index';
import { setStation } from "./actions";
import isMobile from "./helpers/isMobile";

const selectedStation = localStorage.getItem('selectedStation');
if (selectedStation) {
  store.dispatch(setStation({
    stationCheck: true,
    station: JSON.parse(selectedStation),
  }));
}

isMobile(store.dispatch);

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <Route component={AppMain} />
    </Provider>
  </BrowserRouter>
)

render(<App />, document.getElementById('root'));
