import React, { Component } from 'react';
import ReactTable from "react-table";
import { numberFormat } from "../../../../services/helpers";
import { selectDefTableSize } from '../../../../selectors/screenSelectors';
import { connect } from 'react-redux';
import NumberInput from '../../../common/table/NumberInput';
import DatePicker from '../../../common/table/DatePicker';
import { format } from 'date-fns'

class Table extends Component {

    constructor(props){
        super(props);
        this.state = {

        };
    }

    render(){
        const { defaultPageSize } = this.props;
        return (
            <div className={`table-main-wrap ${!this.props.propsAuditArr.length && this.props.propsGetDataStatus == 'pending' ? 'table-main-wrap_loading' : '' }`}>
                <div className="table-header">Інформація про касу</div>
                <ReactTable
                    getTheadFilterThProps={(rowInfo) => {
                        return {
                            style: {
                                overflow: 'visible',
                                textAlign: 'left'
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.setState({
                                        selected: rowInfo.index
                                    })
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.state.selected ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.state.selected ? 'white' : 'black',
                                    cursor: 'pointer'
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={this.props.propsAuditArr}
                    columns={[
                        {
                            Filter: DatePicker,
                            Header: "Дата вилучення",
                            minWidth: 170,
                            id: "DateOutNotNull",
                            accessor: item => {
                                return item.DateOutNotNull != null && format(new Date(item.DateOutNotNull), 'd.M.yyyy') !== '31.12.9999' ? new Date(item.DateOutNotNull).toLocaleString() : '--------------'
                            }
                        },
                        {
                            Filter: NumberInput,
                            id: "Number",
                            Header: "Кількість купюр",
                            accessor: "Number"
                        },
                        {
                            Filter: NumberInput,
                            Header: "Сума у касі",
                            id: "Amount",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.Amount)
                            }
                        },
                        {
                            Filter: NumberInput,
                            Header: "Сума ЕККР, грн.",
                            id: "AmountMariya",
                            className: "rightAlign",
                            accessor: item => {
                                return item.AmountMariya !== null ? numberFormat(item.AmountMariya)  : '-----------'
                            }
                        },
                        {
                            Header: "Сума різниці, грн.",
                            Filter: NumberInput,
                            id: "AmountDifference",
                            accessor: item => {
                                return ( <div className={`rightAlign ${ item.AmountDifference < 0 ? 'red-font' : '' }`}> {item.AmountDifference !== null ? numberFormat(item.AmountDifference) : '-----------' } </div> )
                            }
                        },
                    ]}
                    defaultPageSize={defaultPageSize}
                    className=" -highlight"
                    pageSize={this.props.pageSize}
                    page={this.props.page}
                    onPageSizeChange={this.props.handleChangePageSize}
                    onPageChange={this.props.handleChangePage}
                    manual={true}
                    filterable
                    sortable
                    multiSort
                    onFilteredChange={this.props.handleOnFilterChange}
                    onSortedChange={this.props.handleOnSortChange}
                    filtered={this.props.filtered}
                    sorted={this.props.sorted}
                    pages={Math.ceil(this.props.count / this.props.pageSize)}
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                />
                <div className="loading"></div>
            </div>
        )

    }
}

const mapStateToProps = state => ({
    defaultPageSize: selectDefTableSize(state),
});

export default connect(mapStateToProps)(Table);
