import React, { Component } from 'react';

import api from "../../../../../../api";

import {normalizeNumberMaxDecimals, renderError} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import {hideModal, showModal} from "../../../../../../actions/modals";
import {connect} from "react-redux";
import Spiner from "../../../../../common/Spiner";
import Select from "../../../../../common/Select";
import DateTimePicker from "react-datetime-picker";

import {
    toEmployeeList,
    toCompanyList,
    toDepartmentList,
    toFuelList,
} from "../../../../../../helpers/toLists";

const DAYS_ARRAY = [0, 7, 30, 90, 180, 360];

const DAYS_LABELS = {
    0: 'Вказати',
    7: 'Тиждень',
    30: 'Місяць',
    90: 'Квартал',
    180: 'Півроку',
    360: 'Рік',
}

class CouponModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            company: '',
            department: '',
            employee: '',
            fuelType: '',
            capacity: '',
            valid: '',
            partially: false,
            typeTerm: '',
            days: 0,
            price: 0,
            validation: [],
            callback: () => {},
            data: {
                companyList: [],
                departmentList: [],
                employeeList: [],
                fuelList: [],
            },
        };
    }

    getDays = () => {
        const {
            Created,
            Valid,
        } = this.props.propsData;
        if (!Valid || !Created) return 0;
        const created = (new Date(Created)).getTime();
        const valid = (new Date(Valid)).getTime();
        const days = Math.floor((valid - created) / (1000 * 3600 * 24));
        return DAYS_ARRAY.includes(days) ? days : 0;
    };

    async componentDidMount () {
        const {
            Employee,
            Capacity,
            Partially,
            Valid,
            TypeFuel,
            TypeTerm,
            Price,
        } = this.props.propsData;
        const [companyList, fuelList, departmentList, employeeList] = await Promise.all([
            api.apiGetCompanyList().then(toCompanyList),
            api.apiGetFuelList().then(toFuelList),
            api.apiGetDepartmentData({
                ID: Employee.Department.Company.ID
            }).then(toDepartmentList),
            api.apiGetEmployeeData({
                ID: Employee.Department.ID
            }).then(toEmployeeList)
        ]);
        this.setState({
            callback: this.props.propsData.callback,
            company: {
                value: Employee.Department.Company.ID, label: Employee.Department.Company.Name
            },
            department: {
                value: Employee.Department.ID, label: Employee.Department.Name
            },
            employee: {
                value: Employee.ID, label: `${Employee.Surname} ${Employee.Name} ${Employee.Patronymic}`
            },
            fuelType: {
                label: TypeFuel.FuelName, value: TypeFuel.ID
            },
            capacity: Capacity,
            valid: Valid,
            partially: Partially,
            price: Price,
            typeTerm: !TypeTerm ? '' : {
                label: TypeTerm.TermName, value: TypeTerm.ID
            },
            days: this.getDays(),
            data: {
                companyList,
                departmentList,
                employeeList,
                fuelList,
            },
        });
    }

    handleCompany = async (selectedOption) => {
        this.setState({
            company: selectedOption || '',
            department: '',
            employee: '',
        });

        const data = JSON.parse(JSON.stringify(this.state.data));

        if (!selectedOption) {
            data.departmentList = [];
            data.employeeList = [];
            this.setState({
                data
            });
            return;
        }
        data.departmentList = await api.apiGetDepartmentData({
            ID: selectedOption.value
        }).then(toDepartmentList);

        data.employeeList = [];

        this.setState({
            data
        });
    };

    handleDepartment = async (selectedOption) => {
        this.setState({
            department: selectedOption || '',
            employee: '',
        })

        const data = JSON.parse(JSON.stringify(this.state.data));
        if (!selectedOption) {
            data.employeeList = [];
            this.setState({
                data
            });
            return;
        }
        data.employeeList = await api.apiGetEmployeeData({
            ID: selectedOption.value
        }).then(toEmployeeList);

        this.setState({
            data
        });
    };

    handleEmployee = async (selectedOption) => {
        this.setState({
            employee: selectedOption || ''
        })
    };

    handleChangeFuel = (selectedOption) => {
        this.setState({
            fuelType: selectedOption || ''
        });
    };

    handleTypeTerm = (selectedOption) => {
        this.setState({
            typeTerm: selectedOption || ''
        });
    };

    handleValid = (valid) => {
        this.setState({
            valid
        });
    }

    handleCalculateValid = (event) => {
        const date = new Date();
        const days = Number(event.target.value);
        date.setDate(date.getDate() + days)
        date.setMinutes(0)
        date.setHours(0);
        const valid = !days ? '' : date;
        this.setState({
            valid,
            days,
        });
    }

    handlePartially = (event) => {
        this.setState({
            partially: event.target.checked,
            typeTerm: !event.target.checked ? '' : this.state.typeTerm
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleSave = () => {
        const optionalValidations = [];

        if (this.state.partially) {
            optionalValidations.push({
                id: 'typeTerm', name: 'Частково за період', type: 'EMPTY', value: this.state.typeTerm
            })
        }
        const validationResult = validator.arrayValidation([
            { id: 'company', name: 'Назва компанії', type: 'EMPTY', value: this.state.company },
            { id: 'department', name: 'Назва відділу', type: 'EMPTY', value: this.state.department },
            { id: 'employee', name: 'Працівник', type: 'EMPTY', value: this.state.employee },
            { id: 'fuelType', name: 'Тип палива', type: 'EMPTY', value: this.state.fuelType },
            { id: 'capacity', name: 'Об’єм', type: 'EMPTY', value: this.state.capacity },
            ...optionalValidations
        ]);

        if (validationResult.state !== 'success') {
            this.setState({
                validation: validationResult.errorArr
            });
            return;
        }

        this.setState({ loading: true });
        const dataToSend = {
            ID: this.props.propsData.ID,
            EmployeeID: +this.state.employee.value,
            TypeFuelID: +this.state.fuelType.value,
            Capacity: +this.state.capacity,
            Valid: this.state.valid || null,
            Partially: this.state.partially,
            Price: +this.state.price || null,
            TypeTermID: !this.state.typeTerm ? null : +this.state.typeTerm.value,
        };

        api.apiPatchCoupon(dataToSend).then(res => {
            this.setState({ loading: false });
            if(res.status == 204){
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування талона', description: 'Талон був успішно відредагований', type: true })
                this.state.callback();
            } else {
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування талона', description: 'Талон не був відредагований. Зверніться до адміністратора', type: false })
            }
        }).catch(error => {
            this.setState({ loading: false });
            this.props.dispatchHideModal();
            this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування талона', description: error.response.data.message, type: false });
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати талон</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Назва компанії</h5>
                                <Select
                                    isClearable
                                    placeholder="Виберіть компанію"
                                    value={this.state.company}
                                    onChange={this.handleCompany}
                                    options={this.state.data.companyList}
                                />
                            </div>
                            <div className="form-group-error">
                                { renderError('company', this.state.validation) }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Назва відділу</h5>
                                <Select
                                    isClearable
                                    placeholder="Виберіть відділ"
                                    value={this.state.department}
                                    onChange={this.handleDepartment}
                                    options={this.state.data.departmentList}
                                />
                            </div>
                            <div className="form-group-error">
                                { renderError('department', this.state.validation) }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Працівник</h5>
                                <Select
                                    isClearable
                                    placeholder="Виберіть працівника"
                                    value={this.state.employee}
                                    onChange={this.handleEmployee}
                                    options={this.state.data.employeeList}
                                />
                            </div>
                            <div className="form-group-error">
                                { renderError('employee', this.state.validation) }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Тип палива</h5>
                                <Select
                                    isClearable
                                    placeholder="Виберіть тип палива"
                                    value={this.state.fuelType}
                                    onChange={this.handleChangeFuel}
                                    options={this.state.data.fuelList}
                                />
                            </div>
                            <div className="form-group-error">
                                { renderError('fuelType', this.state.validation) }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Об’єм, л.</h5>
                                <div style={{
                                    width: '100%',
                                    marginLeft: '40px'
                                }}>
                                    <input
                                        placeholder="Введіть об’єм"
                                        type="number"
                                        className="form-control"
                                        name="capacity"
                                        data-type="number"
                                        step="0.01"
                                        value={this.state.capacity || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group-error">
                                    { renderError('capacity', this.state.validation) }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ціна</h5>
                                <div style={{
                                    width: '100%',
                                    marginLeft: '40px'
                                }}>
                                    <input
                                        placeholder="Введіть ціну"
                                        type="number"
                                        className="form-control"
                                        name="price"
                                        data-type="number"
                                        step="0.01"
                                        value={this.state.price || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Частково покривається</h5>
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="partially"
                                            value={this.state.partially}
                                            onChange={this.handlePartially}
                                            checked={this.state.partially}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Частково за період</h5>
                                <Select
                                    isDisabled={!this.state.partially}
                                    placeholder="Виберіть період"
                                    value={this.state.typeTerm}
                                    onChange={this.handleTypeTerm}
                                    options={this.props.typeTerm}
                                    isClearable
                                />
                            </div>
                            <div className="form-group-error">
                                { renderError('typeTerm', this.state.validation) }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Дійсний до</h5>
                                <div>
                                    <DateTimePicker
                                        disabled={this.state.days}
                                        locale="uk-UK"
                                        onChange={this.handleValid}
                                        value={this.state.valid}
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                            <div className="form-group-error">
                                { renderError('valid', this.state.validation) }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div style={{ marginLeft: '140px' }}>
                                <h5></h5>
                                <div className="checkbox">
                                    {
                                        DAYS_ARRAY.map((days, i) => (
                                            <label key={days} style={{ marginBottom: '22px' }}>
                                                {DAYS_LABELS[days]}
                                                {': '}
                                                <input
                                                    checked={this.state.days === days}
                                                    onChange={this.handleCalculateValid}
                                                    name="days"
                                                    type="radio"
                                                    value={days}
                                                    style={{ verticalAlign: 'top' }}
                                                />
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponModal);
