import React, { Component } from 'react';
import {normalizeNumberMaxDecimals, renderError} from "../../../../services/helpers";
import validator from "../../../../services/DataValidator";
import api from '../../../../api/index';
import {hideModal, showModal} from "../../../../actions/modals";
import {connect} from "react-redux";
import Spiner from "../../../common/Spiner";

class OwnerEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            name: '',
            address: '',
            govermentCode: '',

            validation: [],
            callback: () => {},
        };
    }

    componentDidMount(){
        this.setState({
            ID: this.props.propsData.ID,
            name: this.props.propsData.Name,
            address: this.props.propsData.Address,
            govermentCode: this.props.propsData.GovermentCode,

            callback: this.props.propsData.callback
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'name', name: 'Імя', type: 'EMPTY', value: this.state.name },
            { id: 'address', name: 'Адреса', type: 'EMPTY', value: this.state.address },
            { id: 'govermentCode', name: 'Код ЄДРПОУ', type: 'EMPTY', value: this.state.govermentCode },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                ID: this.state.ID,
                Name: this.state.name,
                Address: this.state.address,
                GovermentCode: this.state.govermentCode
            };

            api.apiEditOwner(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 204){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування власника', description: 'Власник був успішно змінений', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування власника', description: 'Власник не був змінений. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування власника', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати власника</h5>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ім'я</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть ім'я власника"
                                        type="tetx"
                                        className="form-control"
                                        name="name"

                                        value={this.state.name}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('name', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Адреса</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть адресу"
                                        type="text"
                                        className="form-control"
                                        name="address"

                                        value={this.state.address}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('address', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Код ЄДРПОУ</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть код ЄДРПОУ"
                                        type="text"
                                        className="form-control"
                                        name="govermentCode"

                                        value={this.state.govermentCode}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('govermentCode', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerEditModal);
