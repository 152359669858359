import React, { useState } from 'react';

import pluralize from "../../../../../../helpers/pluralize";
import Spiner from "../../../../../common/Spiner";
import api from "../../../../../../api";
import {hideModal, showModal} from "../../../../../../actions/modals";
import {connect} from "react-redux";

const CouponLoadModal = ({ closeModal, propsData: { arraySort = [] } = {}, dispatchHideModal, dispatchShowModal, ...rest }) => {
    const [loading, setLoading] = useState('');

    const loadQRCode = ({ ID, Capacity, Valid, TypeFuel }, i) => api.apiCreateCouponQRCode(ID).then(dataUrl => {
        const nameArray = [];
        if (TypeFuel) {
            nameArray.push(`Тип Палива ${TypeFuel.FuelName}`)
        }
        if (Capacity) {
            nameArray.push(`Номінал ${Capacity}`)
        }

        nameArray.push(`Номер ${i+1}`);

        const name = nameArray.join(', ')
        const link = document.createElement("a");
        link.setAttribute("href", `data:image/png;base64,${dataUrl}`);
        link.setAttribute("download", name);
        link.click();
    });

    const propsOnLoad = () => {
        setLoading('pending');
        Promise.all(
            arraySort.map(loadQRCode)
        ).then(() => {
            setLoading('success');
            dispatchHideModal();
            dispatchShowModal('INFO_MODAL', { title: 'Завантажння талонів', description: 'Талони були успішно завантажені', type: true })
        }).catch(() => {
            setLoading('success');
            dispatchHideModal();
            dispatchShowModal('INFO_MODAL', { title: 'Завантажння талонів', description: 'Талони не були завантажені. Зверніться до адміністратора', type: false });
        })
    }

    return (
        <div>
            <div className="modal-header">
                <button type="button" className="close" onClick={closeModal}>
                    <i className="fa fa-times" />
                </button>
                <h5 className="modal-title">
                    Завантаження талонів
                </h5>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-lg-12">
                        <div>
                            {`Ви дійсно бажаєте завантажити ${arraySort.length} ${pluralize({ plurals: ['талон', 'талони', 'талонів'], count: arraySort.length })}?`}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="row">
                    <div className="col-lg-12">
                        <button
                            disabled={loading}
                            onClick={propsOnLoad}
                            type="button"
                            className="btn btn-primary"
                        >
                            {
                                !loading
                                    ? <span>Завантажити</span>
                                    : <span>Завантажуємо</span>
                            }
                            {!loading ? null : <Spiner />}
                        </button>
                        <button onClick={closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(null, mapDispatchToProps)(CouponLoadModal);