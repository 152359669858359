import React from 'react';
import { components } from 'react-select';

const mapping = {
    0: 'fa-dollar',
    1: 'fa-card',
    5: 'fa-qrcode'
}

const PaymentTypeOption = ({ children, value, ...props }) => {
    return (
        <components.Option {...props} value={value}>
            <i className={`fas ${mapping[value] || 'fa-empty'}`} />
            {' '}
            {children}
        </components.Option>
    )
}

export default PaymentTypeOption;

