import ReactTable from "react-table";
import StringInput from '../../../../common/table/StringInput';
import { selectDefTableSize } from '../../../../../selectors/screenSelectors';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class PetrolBoxClientTable extends Component {

    constructor(props){
        super(props);
        this.state = { };
    }

    render() {
        const { defaultPageSize } = this.props;
        return(
            <div className={`table-main-wrap ${!this.props.propsArray.length && this.props.propsGetDataStatus == 'pending' ? 'table-main-wrap_loading' : '' }`}>
            <div className="table-header">Інформація про користувачів PetrolBox.Client</div>
            <ReactTable
                pageSize={this.props.pageSize}
                page={this.props.page}
                onPageSizeChange={this.props.handleChangePageSize}
                onPageChange={this.props.handleChangePage}
                manual={true}
                filterable
                sortable
                multiSort
                onFilteredChange={this.props.handleOnFilterChange}
                onSortedChange={this.props.handleOnSortChange}
                filtered={this.props.filtered}
                sorted={this.props.sorted}
                pages={Math.ceil(this.props.count / this.props.pageSize)}
                getTheadFilterThProps={(rowInfo) => {
                    return {
                        style: {
                            overflow: 'visible',
                            textAlign: 'left'
                        }
                    }
                }}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            onClick: () => {
                                this.props.propsRowClick({
                                    id: rowInfo.row._original.ID,
                                    index: rowInfo.index
                                });
                            },
                            style: {
                                backgroundColor: rowInfo.index === this.props.index ? '#B8B8B8' : 'white',
                                color: rowInfo.index === this.props.index ? 'white' : 'black',
                                cursor: 'pointer',
                            }
                        }
                    }else{
                        return {}
                    }
                }}
                data={this.props.propsArray}

                columns={[
                    {
                        Filter: StringInput,
                        minWidth: 400,
                        Header: "П.І.Б.",
                        id: "User/DisplayName",
                        accessor: row => `${row.DisplayName || '-----------'}`
                    },
                    {
                        Filter: StringInput,
                        minWidth: 400,
                        Header: "Компанія",
                        id: "Company/Name",
                        accessor: row => `${row.CompanyName || '-----------'}`
                    },
                    {
                        Filter: StringInput,
                        minWidth: 400,
                        Header: "Email",
                        id: 'User/Mail',
                        accessor: row => `${row.Mail || '-----------'}`
                    }
                ]}
                defaultPageSize={defaultPageSize}
                className=" -highlight"
                NoDataComponent={() => null}
                previousText="Назад"
                nextText="Вперед"
                rowsText="рядків"
                pageText="Сторінка"
                ofText="з"
            />
            <div className="loading"></div>
        </div>  
        )
    }
}

const mapStateToProps = state => ({
    defaultPageSize: selectDefTableSize(state),
});

export default connect(mapStateToProps)(PetrolBoxClientTable)