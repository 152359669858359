import React, { Component } from 'react';
import Table from './items/Table';
import { connect } from 'react-redux';
import api from '../../../api';
import { showModal } from "../../../actions/modals";
import Sidebar from '../cod/Sidebar';
import Wrapper from '../index';
import Station from '../elements/Station';
import { toPascalCase } from "../../../services/helpers";
import getCount from "../../../helpers/getCount";
import sortConverter from "../../../helpers/sortConverter";
import { dateConverter, numberConverter, createFilter } from "../../../helpers/filterConverters";
import { setFilterData } from '../../../actions/filtersReducers';

const convertersMap = {
    DateOutNotNull: dateConverter,
    Number: numberConverter,
    Amount: numberConverter,
    AmountMariya: numberConverter,
    AmountDifference: numberConverter,
}

class Index extends Component {

    constructor(props){
        super(props);
        const from = new Date(new Date().setHours(0,0,0,0));
        from.setDate(from.getDate() - 1);
        this.state = {
            filtered: [{
                id: 'DateOutNotNull', value: {
                    from,
                    to: null,
                }
            }],
            sorted: [{ id: 'DateOutNotNull', desc: true }],
            count: 0,
            pageSize: 10,
            page: 0,
            auditArr: [],
            sortAuditArr: [],
        };
    }

    static filterType = 'CACHE_REGISTER'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.filtered, Index.filterType));
    }

    async componentDidMount(){
        this.setState({
            filtered: this.props.filtersReducer[Index.filterType] || this.state.filtered,
        }, this.handleFilter)
    }

    handleChangePageSize = (pageSize) => {
        this.setState({ page: 0, pageSize }, () => this.handleFilter())
    }

    handleChangePage = (page) => {
        this.setState({ page }, () => this.handleFilter())
    }

    handleSort = () => {
        this.setState({
            page: 0,
        }, this.props.handleFilter)
    }

    handleFilter = async () => {
        const data = {
            filter: createFilter(this.state.filtered, convertersMap),
            order: sortConverter(this.state.sorted),
            top: this.state.pageSize,
            skip: this.state.pageSize * this.state.page
        };

        this.setState({
            getDataStatus: 'pending'
        });

        let res = await api.apiGetCashRegisterData(data);

        this.setState({
            getDataStatus: 'success',
            count: getCount(res),
            sortAuditArr: toPascalCase(res.data.value)
        });
    };

    handleOnFilterChange = (filtered = []) => {
        this.setState({ filtered }, this.handleFilter)
    }

    handleOnSortChange = (sorted = []) => {
        this.setState({ sorted }, this.handleFilter);
    }

    handleClearFilter = async () => {
        this.setState({
            page: 0,
            filtered: []
        }, this.handleFilter);
    };

    render(){
        return (
        <Wrapper>
            <div className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <Station />
                    </div>
                </div>
                <Table
                    propsGetDataStatus={this.state.getDataStatus}
                    propsAuditArr={this.state.sortAuditArr}
                    handleChangePageSize={this.handleChangePageSize}
                    handleChangePage={this.handleChangePage}
                    count={this.state.count}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    handleOnFilterChange={this.handleOnFilterChange}
                    handleOnSortChange={this.handleOnSortChange}
                    filtered={this.state.filtered}
                    sorted={this.state.sorted}
                />
                <Sidebar
                    showNavigation={false}
                    onClear={this.handleClearFilter}
                />
            </div>
        </Wrapper>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
