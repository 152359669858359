import React, { Component } from 'react';
import {renderError} from "../../../../services/helpers";
import validator from "../../../../services/DataValidator";
import api from "../../../../api";
import { hideModal, showModal } from "../../../../actions/modals";
import {connect} from "react-redux";
import DateTimePicker from "react-datetime-picker";
import Select from "../../../common/Select";
import contentDisposition from 'content-disposition'
import Spiner from "../../../common/Spiner";
import {toCompanyList} from "../../../../helpers/toLists";
let FileSaver = require('file-saver');

class ClientCardOperationsReport extends Component {

    constructor(props) {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        super(props);
        this.state = {
            loading: false,
            closed: false,
            company: '',
            fromDate: new Date(date.setHours(0,0,0,0)),
            toDate: new Date(date.setHours(23,59,0,0)),
            companyList: [],
            validation: [],
            callback: () => {},
        }
    }

    async componentDidMount(){
        this.setState({
            companyList: [{ value: 0, label: "Всі" }].concat(await api.apiGetCompanyList().then(toCompanyList)),
            company: { value: 0, label: "Всі" },
        })
    }

    handleFromDate = fromDate => this.setState({ fromDate });
    handleToDate = toDate => this.setState({ toDate });

    handleCompany = (selectedOption) => {
        this.setState({
            company: selectedOption
        });
    };

    handleSave = async () => {

        const {title, name } = this.props.propsData;

        let validationResult = validator.arrayValidation([
            { id: 'company', name: 'Компанія', type: 'EMPTY', value: this.state.company },
            { id: 'fromDate', name: 'З', type: 'EMPTY', value: this.state.fromDate },
            { id: 'toDate', name: 'До', type: 'EMPTY', value: this.state.toDate },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let fromDate = (new Date(this.state.fromDate).toLocaleDateString("uk-UA")).split(',')[0];
            let toDate = (new Date(this.state.toDate).toLocaleDateString("uk-UA")).split(',')[0];

            fromDate = fromDate.split('.');
            toDate = toDate.split('.');

            let dataToSend = {
                company: this.state.company.value,
                fromDate: fromDate.indexOf('Invalid Date') === -1 ? `${fromDate[2]}-${fromDate[1]}-${fromDate[0]}` : null,
                toDate: toDate.indexOf('Invalid Date') === -1 ? `${toDate[2]}-${toDate[1]}-${toDate[0]}` : null,
                route: name
            }; 

            this.props.dispatchHideModal();
            this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" формується...', type: null })

            try {
                const res = await api.apiCcreateClientCardOperationsReport(dataToSend);
                this.setState({ loading: false});
                const fileName = contentDisposition.parse(res.headers['content-disposition']).parameters.filename;

                let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
                FileSaver.saveAs(blob, fileName);

                if(res.status == 200) {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" був успішно сформований.', type: true })
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" не був сформований. Зверніться до адміністратора', type: false })
                }

                 
            } catch (e) {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" не був сформований. Зверніться до адміністратора', type: false })
            }
        }

        this.setState({
            validation: validationResult.errorArr
        });
    }

    render(){
        const { fromDate, toDate, companyList, isToDateDisabled } = this.state
        const { title } = this.props.propsData;
        return(
            <div className="noncom-modal">
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">{ title }</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={companyList}
                                        placeholder="Виберіть Компанію ..."
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <div className="df ai-center">
                                    <h5>З</h5>
                                    <DateTimePicker
                                        locale="uk-UK"
                                        className="react-datetime-picker_margin-l-20"
                                        onChange={this.handleFromDate}
                                        value={fromDate}
                                    />
                                    <div className="form-group-error">
                                        { renderError('fromDate', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <div className="df ai-center">
                                    <h5>До</h5>
                                    <DateTimePicker
                                        disabled={isToDateDisabled}
                                        locale="uk-UK"
                                        className="react-datetime-picker_margin-l-20"
                                        onChange={this.handleToDate}
                                        value={toDate}
                                    />
                                    <div className="form-group-error">
                                        { renderError('toDate', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Створити</span>
                                        : <span>Створюємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return{
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientCardOperationsReport);