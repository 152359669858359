import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import {normalizeNumberMaxDecimals, validateText, renderError} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import api from "../../../../../../api";
import { hideModal, showModal } from "../../../../../../actions/modals";
import Spiner from "../../../../../common/Spiner";
import {toCardList, toCompanyList, toDepartmentList} from "../../../../../../helpers/toLists";

class EmployeeCreateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                companyList: [],
                departmentList: [],
                cardList: []
            },

            company: '',
            department: '',
            surname: '',
            name: '',
            patronymic: '',
            phoneNumber: '',
            card: '',
            password: '',
            limit: '',
            typeTerm: '',
            closed: false,

            callback: () => {},

            validation: []
        };
    }

    async componentDidMount(){
        let [
            companyList,
            cardList,
        ]= await Promise.all([
            api.apiGetCompanyList().then(toCompanyList),
            api.apiGetCardData().then(toCardList),
        ]);

        this.setState({
            data: {
                companyList,
                departmentList: [],
                cardList,
            },
            callback: this.props.propsData.callback
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        if(event.target.dataset.type == 'text'){
            value = validateText(value);
        }

        this.setState({
            [name]: value
        });
    };

    handleCompany = async (selectedOption) => {
        const data = this.state.data
        data.departmentList = await api.apiGetDepartmentData({ ID: selectedOption.value }).then(toDepartmentList)
        const { company, department } = this.state;
        this.setState({
            company: selectedOption,
            department: Number(company.value) !== Number(selectedOption.value) ? null : department,
            data
        });
    };

    handleDepartment = (selectedOption) => {
        this.setState({
            department: selectedOption
        });
    };

    handleCard = (selectedOption) => {
        this.setState({
            card: selectedOption
        });
    };

    handleTypeTerm = (selectedOption) => {
        this.setState({
            typeTerm: selectedOption
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'company', name: 'company', type: 'EMPTY', value: this.state.company },
            { id: 'department', name: 'department', type: 'EMPTY', value: this.state.department },
            { id: 'surname', name: 'surname', type: 'EMPTY', value: this.state.surname },
            { id: 'name', name: 'name', type: 'EMPTY', value: this.state.name },
            { id: 'patronymic', name: 'patronymic', type: 'EMPTY', value: this.state.patronymic },
            { id: 'phoneNumber', name: 'phone number', type: 'EMPTY', value: this.state.phoneNumber },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                CardID: !this.state.card || !this.state.card.value ? null : +this.state.card.value,
                Closed: this.state.closed,
                DepartmentID: +this.state.department.value,
                Name: this.state.name,
                Surname: this.state.surname,
                Patronymic: this.state.patronymic,
                Password: !this.state.password ? '' : this.state.password,
                PhoneNumber: this.state.phoneNumber,
                Limit: !(+this.state.limit) ? null : +this.state.limit,
                TypeTermID: this.state.typeTerm == null ? null : +this.state.typeTerm.value,
            };

            api.apiCreateEmployee(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 201){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити працівника', description: 'Працівник був успішно створений', type: true });
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити працівника', description: 'Працівник не був створений. Зверніться до адміністратора', type: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити працівника', description: error.response.data.message, type: false });
            });

        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Створити працівника</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть компанію"
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={this.state.data.companyList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Відділ</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть відділ"
                                        value={this.state.department}
                                        onChange={this.handleDepartment}
                                        options={this.state.data.departmentList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('department', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Прізвище</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть прізвище"
                                        type="text"
                                        className="form-control"
                                        name="surname"
                                        value={this.state.surname}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('surname', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ім'я</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть ім'я"
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('name', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>По-батькові</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть по-батькові"
                                        type="text"
                                        className="form-control"
                                        name="patronymic"
                                        value={this.state.patronymic}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('patronymic', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Номер телефона</h5>
                                <div className="form-group">
                                    <PhoneInput
                                        placeholder="Введіть номер телефона"
                                        value={ this.state.phoneNumber }
                                        onChange={ phone => this.setState({ phoneNumber: phone }) }
                                    />
                                    <div className="form-group-error">
                                        { renderError('phoneNumber', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Картка</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть картку"
                                        value={this.state.card}
                                        onChange={this.handleCard}
                                        options={this.state.data.cardList}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Пароль</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть пароль"
                                        type="password"
                                        className="form-control"
                                        name="password"

                                        value={this.state.password}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Кількість ліміту</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть кількість ліміту"
                                        type="text"
                                        className="form-control"
                                        name="limit"
                                        data-type="number"

                                        value={this.state.limit}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('limit', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Термін ліміту</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть термін ліміту"
                                        value={this.state.typeTerm}
                                        onChange={this.handleTypeTerm}
                                        options={this.props.typeTerm}
                                        isClearable
                                    />
                                    <div className="form-group-error">
                                        {renderError('typeTerm', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div><div className="col-lg-12">
                        <div className="modal__row">
                            <h5>Заблоковано</h5>
                            <label>
                                <input
                                    type="checkbox"
                                    name="closed"

                                    value={this.state.closed}
                                    onChange={this.handleInputChange}
                                    checked={this.state.closed}
                                />
                                <div className="form-group-error">
                                    {renderError('closed', this.state.validation)}
                                </div>
                            </label>
                        </div>
                    </div>

                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCreateModal);
