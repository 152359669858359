export function getParseDate(data){
    let date = new Date( data );
    let yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    if (mm < 10 ) mm = "0" + mm;

    let dd = date.getDate();
    if (dd < 10 ) dd = "0" + dd;

    let hh = date.getHours();
    if (hh < 10 ) hh = "0" + hh;

    let min = date.getMinutes();
    if (min < 10 ) min = "0" + min;

    let ss = date.getSeconds();
    if (ss < 10 ) ss = "0" + ss;

    return yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss;
}

export function typeofCheck(data){
    return typeof data != 'undefined'
}

export function renderError(field, array){
    if(array.length > 0){
        let result = array.find((array) => { return array.field === field; });
        if(typeof result != 'undefined'){
            return result.msg;
        }
    }
    else return '';
}

export function normalizeNumberMaxDecimals(val, max, decimals) {
    let tmpSplit;
    let value = val.replace(/[^0-9.]*/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\.,/g, ',');
    value = value.replace(/\,\./g, ',');
    value = value.replace(/\,{2,}/g, ',');
    value = value.replace(/\.[0-9]+\./g, '.');

    tmpSplit = value.split('.');

    if(typeof tmpSplit[1] != 'undefined' && tmpSplit[1].length > 1){
        value = tmpSplit[0] + "." + tmpSplit[1].substring(0, decimals);
    }

    value = (value[0] == '0' && value[1] == '0') || (value[0] == '0' && +value[1]) ? '0' : value;
    value = value > max ? max : value;
    return !value || value == '.' ? '' : value;
}

export function normalizeNumberData(val, max) {
    let value = val.replace(/[^0-9.]*/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\.,/g, ',');
    value = value.replace(/\,\./g, ',');
    value = value.replace(/\,{2,}/g, ',');
    value = value.replace(/\.[0-9]+\./g, '.');
    value = (value[0] == '0' && value[1] == '0') || (value[0] == '0' && +value[1]) ? '0' : value;
    return !value || value == '.' ? '' : value;
}

export function normalizeNumberData2(val) {
    let value = val.replace(/[^0-9.]*/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\.,/g, ',');
    value = value.replace(/\,\./g, ',');
    value = value.replace(/\,{2,}/g, ',');
    value = value.replace(/\.[0-9]+\./g, '.');
    value = +value;
    value = value.toFixed(0);
    value = parseFloat(value);
    return !value ? 0 : value;
}

export function normalizeNumberData4(val, max) {
    let value = val.replace(/[^0-9.]*/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\.,/g, ',');
    value = value.replace(/\,\./g, ',');
    value = value.replace(/\,{2,}/g, ',');
    value = value.replace(/\.[0-9]+\./g, '.');
    value = +value;
    if(value > max){
        return 0;
    } else {
        value = value.toFixed(0);
        value = parseFloat(value);
        return !value ? 0 : value;
    }
}

export function normalizeNumberData3(val) {
    let value = val.replace(/[^0-9.,]*/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\.,/g, ',');
    value = value.replace(/\,\./g, ',');
    value = value.replace(/\,{2,}/g, ',');
    value = value.replace(/\.[0-9]+\./g, '.');
    value = +value;
    value = value.toFixed(1);

    //value = +value.toFixed(1);
    value = parseFloat(value);
    return !value ? 0 : value;
}

export function removeByKey(array, params){
    array.some(function(item, index) {
        return (array[index][params.key] === params.value) ? !!(array.splice(index, 1)) : false;
    });
    return array;
}

export let getTimeForTimer = (data) => {
    let currentTime = new Date().getTime();
    let startPrivateTime = new Date(data).getTime();

    let startTimer = (startPrivateTime - currentTime > 0 ) ? (startPrivateTime - currentTime) : 0;

    return parseFloat(startTimer/1000).toFixed(1);
};

export function scrollTo(data){
    let content = document.querySelector(data.selector);
    window.scroll(0, content.offsetTop);
}

export function removeFromStringFromArray(arr, what) {
    var found = arr.indexOf(what);

    while (found !== -1) {
        arr.splice(found, 1);
        found = arr.indexOf(what);
    }
}

export const numberFormat = (data) => {
    data = data.toFixed(2);

    data = data.toString();

    let tmp = data.split('.');

    let first = tmp[0].replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');

    let second = typeof tmp[1] == 'undefined' ? '00' : tmp[1];

    return first + '.' + second;
};

export const validateText = (str) => {let ars = str.replace(/[^a-zA-ZА-Яа-яёЁЇїІіЄєҐґ]/gi,'').replace(/\s+/gi,', '); return ars;};

export const toPascalCase = (o) => {
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
        return o.map((value) => {
            if (typeof value === "object") {
                value = toPascalCase(value)
            }
            return value
        })
    } else {
        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                if(origKey == 'id'){
                    newO['ID'] = o[origKey]
                    continue
                }
                newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toPascalCase(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
};