import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';

import {normalizeNumberMaxDecimals, renderError} from "../../../../services/helpers";
import validator from "../../../../services/DataValidator";
import api from "../../../../api";
import { hideModal, showModal } from "../../../../actions/modals";
import Spiner from "../../../common/Spiner";
import {toFuelList} from "../../../../helpers/toLists";

class EditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                fuelList: []
            },
            ID: null,
            fuelType: '',
            stationID: '',
            number: '',
            barrelID: '',
            barrel: '',
            mariyaTapID: '',
            callback: () => {},
            validation: []
        };
    }

    async componentDidMount(){
        this.setState({
            data: {
                fuelList: api.apiGetFuelList().then(toFuelList)
            },

            ID: this.props.propsData.ID,
            fuelType: this.props.propsData.TypeFuel === null ? '' : {
                value: this.props.propsData.Barrel.TypeFuel.ID, label: this.props.propsData.Barrel.TypeFuel.FuelName
            },
            stationID: this.props.propsData.StationID,
            number: this.props.propsData.Number,
            barrelID: this.props.propsData.BarrelID,
            barrel: this.props.propsData.Barrel,
            mariyaTapID: this.props.propsData.MariyaTapID,

            callback: this.props.propsData.callback
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleFuelType = (selectedOption) => {
        this.setState({
            fuelType: selectedOption
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'number', name: 'Номер заправочного пістолета', type: 'EMPTY', value: this.state.number }
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                ID: +this.state.ID,
                StationID: +this.state.stationID,
                Number: +this.state.number,
                BarrelID: +this.state.barrelID,
            };

            api.apiEditTapData(dataToSend).then(res => {
                if(res.status == 204){

                    let {
                        ID,
                        AddressID,
                        LowCapacity,
                        Number,
                        Price,
                        WarningCapacity
                    } = this.state.barrel;

                    let dataToSend = {
                        ID: ID,
                        AddressID: AddressID,
                        LowCapacity: LowCapacity,
                        Number: Number,
                        Price: Price,
                        WarningCapacity: WarningCapacity,
                        TypeFuelID: +this.state.fuelType.value
                    };
                    api.apiEditBarrel(dataToSend).then(res => {
                        this.setState({ loading: false });
                        if(res.status == 204) {
                            this.props.dispatchHideModal();
                            this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати запис', description: 'Запис був успішно відредагований', type: true })
                            this.state.callback();
                        } else {
                            this.props.dispatchHideModal();
                            this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати запис', description: 'Запис не був відредагований. Зверніться до адміністратора', type: false })
                        }
                    });
                } else {
                    this.setState({ loading: false });
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати запис', description: 'Запис не був відредагований. Зверніться до адміністратора', type: false })
                }
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати запис</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Номер ТРК</h5>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="stationID"
                                        data-type="number"

                                        readOnly
                                        value={this.state.stationID}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('stationID', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Номер заправочного пістолета</h5>
                                <div className="form-group">
                                    <div>
                                        <input
                                            placeholder="Введіть номер заправочного пістолета"
                                            type="text"
                                            className="form-control"
                                            name="number"
                                            data-type="number"

                                            value={this.state.number}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group-error">
                                        { renderError('number', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Тип палива</h5>
                                <div>
                                    <Select
                                        value={this.state.fuelType}
                                        onChange={this.handleFuelType}
                                        options={this.state.data.fuelList}
                                    />
                                    <div className="form-group-error">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
