import React, { Component } from 'react';
import ReactTable from "react-table";
import { numberFormat } from "../../../../services/helpers";

class MiniAuditTable extends Component {

    constructor(props){
        super(props);
        this.state = {
            selected: null,
            rows: 0
        };
    }

    componentDidMount(){
        this.setState({
            rows: this.props.propsMiniAuditArray.length
        });
    }

    componentWillReceiveProps(props){
       this.setState({
           rows: props.propsMiniAuditArray.length
       })
    }

    render(){
        return (
            <>
                <div className="table-header mt-51">Інформація про міні аудит</div>
                <ReactTable
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.setState({
                                        selected: rowInfo.index
                                    });
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.state.selected ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.state.selected ? 'white' : 'black',
                                    cursor: 'pointer'
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={this.props.propsMiniAuditArray}
                    columns={[
                        {
                            Header: "Тип палива",
                            accessor: "FuelName"
                        },
                        {
                            Header: "Відгрузка, загальний об'єм, л",
                            id: "fuelOut",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.SupplyCapacity)
                            }
                        },
                        {
                            Header: "Продаж, загальний об'єм, л",
                            id: "soldVolume",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.SaleCapacity)
                            }
                        },
                        {
                            Header: "Продаж, загальна сума, грн",
                            id: "SaleAmount",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.SaleAmount)
                            }
                        }
                    ]}
                    pageSize={this.state.rows}
                    className=" -highlight"
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                    showPagination={false}
                />
            </>
        )

    }
}

export default MiniAuditTable;
