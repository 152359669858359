import React, { Component } from 'react';
import { connect } from 'react-redux'

import Sidebar from '../../Sidebar';
import Wrapper from '../../../index';
import Table from './items/Table';

import api from '../../../../../api/index';
import {showModal} from "../../../../../actions/modals";
import {toPascalCase} from "../../../../../services/helpers";
import getCount from "../../../../../helpers/getCount";
import { toFuelList } from '../../../../../helpers/toLists'
import sortConverter from "../../../../../helpers/sortConverter";
import { stringConverter, booleanConverter, selectNumberConverter, numberConverter, createFilter, dateConverter } from "../../../../../helpers/filterConverters";
import { setFilterData } from '../../../../../actions/filtersReducers';

const convertersMap = {
    'Employee/Department/Company/Name': stringConverter,
    'Employee/Department/Name': stringConverter,
    'Employee/Name|Employee/Surname|Employee/Patronymic': stringConverter,
    TypeFuelID: selectNumberConverter,
    Capacity: numberConverter,
    Price: numberConverter,
    Created: dateConverter,
    Valid: dateConverter,
    Partially: booleanConverter,
    TypeTermID: selectNumberConverter,
    Printed: booleanConverter,
    IsValid: booleanConverter,
    IsCovered: booleanConverter,
    BalanceCapacity: numberConverter,
}

const isValid = (item) => {
    if (!item.Valid) return true;
    return new Date() <= new Date(item.Valid);
}

class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: {},
            filtered: [],
            sorted: [],
            count: 0,
            pageSize: 10,
            page: 0,
            rowID: null,
            index: null,
            getDataStatus: 'success',
            arraySort: [],
        };
    }

    static filterType = 'COUPON'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.filtered, Index.filterType));
    }

    async componentDidMount(){
        const fuelList = await api.apiGetFuelList().then(toFuelList);
        this.setState({
            filtered: this.props.filtersReducer[Index.filterType] || this.state.filtered,
            data: {
                fuelList,
            },
        }, this.handleSort)
    }

    handleChangePageSize = (pageSize) => {
        this.setState({ page: 0, pageSize }, this.handleSort)
    }

    handleChangePage = (page) => {
        this.setState({ page }, this.handleSort)
    }

    handleSort = async () => {
        const data = {
            filter: createFilter(this.state.filtered, convertersMap),
            order: sortConverter(this.state.sorted),
            top: this.state.pageSize,
            skip: this.state.pageSize * this.state.page
        };

        this.setState({
            getDataStatus: 'pending',
        });
        try {
            let res = await api.apiGetCouponData(data);
            this.setState({
                getDataStatus: 'success',
                count: getCount(res)
            });
            const arraySort = toPascalCase(res.data.value);
            this.setState({
                arraySort
            });
        } catch (e) {
            console.error(e)
            this.setState({
                getDataStatus: 'success',
                arraySort: [],
            });
        }
    };

    handleSetState = (data, callback) => this.setState(data, callback);

    handleRowClick = (data) => {
        this.setState({
            rowID: data.id,
            index: data.index
        });
    };

    handleDelete = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleSort;
        tmpObj.arraySort = this.state.arraySort;
        this.props.dispatchShowModal('COUPON_DELETE_MODAL', tmpObj);
    };

    handleEdit = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleSort;
        this.props.dispatchShowModal('COUPON_EDIT_MODAL', tmpObj);
    };

    handleCreate = () => {
        let tmpObj = {};
        tmpObj.callback = this.handleSort;
        this.props.dispatchShowModal('COUPON_MODAL', tmpObj)
    };

    findObject = () => {
        let tmpObj = {};
        for(let i = 0; i < this.state.arraySort.length; i++){
            if(this.state.rowID == this.state.arraySort[i].ID){
                tmpObj = Object.assign({}, this.state.arraySort[i]);
            }
        }
        return tmpObj;
    };

    propsOnLoad = () => {
        this.props.dispatchShowModal('COUPON_LOAD_MODAL', { arraySort: this.state.arraySort.filter(isValid) })

    }

    handleOnFilterChange = (filtered = []) => {
        this.setState({ filtered }, this.handleSort)
    }

    handleOnSortChange = (sorted = []) => {
        this.setState(
            { sorted }, this.handleSort);
    }

    handleClearFilter = async () => {
        this.setState({
            page: 0,
            filtered: []
        }, this.handleSort);
    };

    render(){
        return (
            <Wrapper>
                <div className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <Table
                                handleChangePageSize={this.handleChangePageSize}
                                handleChangePage={this.handleChangePage}
                                count={this.state.count}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                propsRowID={this.state.rowID}
                                propsRowClick={this.handleRowClick}
                                propsArraySort={this.state.arraySort}
                                propsGetDataStatus={this.state.getDataStatus}
                                index={this.state.index}
                                handleOnFilterChange={this.handleOnFilterChange}
                                handleOnSortChange={this.handleOnSortChange}
                                filtered={this.state.filtered}
                                sorted={this.state.sorted}
                                data={this.state.data}
                            />
                        </div>
                    </div>
                    <Sidebar
                        showLoadClassName={'fas fa-qrcode'}
                        propsOnCreate={this.handleCreate}
                        propsOnEdit={this.handleEdit}
                        propsOnDelete={this.handleDelete}
                        propsOnLoad={!this.state.arraySort.filter(isValid).length ? null : this.propsOnLoad}
                        propsDisableEdit={ this.findObject().ID == null ? true : false }
                        propsDisableDelete={ this.findObject().ID == null ? true : false }
                        propsSelected={{coupon: true}}
                        showNavigation={true}
                        onClear={this.handleClearFilter}
                    />
                </div>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
