import React, { Component } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';

import Spiner from "../../../../common/Spiner";

import api from '../../../../../api/index';
import { hideModal, showModal } from "../../../../../actions/modals";
import validator from "../../../../../services/DataValidator";
import { normalizeNumberMaxDecimals, renderError } from "../../../../../services/helpers";
import {toCompanyList, toUserInformation} from "../../../../../helpers/toLists";

class PetrolBoxClientAddModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: '',
            email: '',
            givenName: '',
            surName: '',
            companyID: '',
            company: '',
            companyList: [],
            validation: [],

            callback: () => { }
        };
    }

   async componentDidMount() {
        this.setState({
            callback: this.props.propsData.callback,
            companyList: await api.apiGetCompanyList().then(toCompanyList)
        });
    }

    handleCompany = (selectedOption) => {

        this.setState({
            company: selectedOption,
            companyID: selectedOption.value
        });
    };

    isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        return emailRegex.test(email);
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (event.target.dataset.type == 'number') {
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handlerInputEmailChange = (event) => {
        this.handleInputChange(event);

        this.setState({
            id: ''
        });
    }
    
    handleInputFind = () => {
        let validationResult = validator.arrayValidation([
            { id: 'email', name: 'Email корректно', type: 'EMPTY', value: !this.isValidEmail(this.state.email) ? '' : 'ok' },
        ])

            if (validationResult.state == 'success') {

                api.apiGetUserInformation(this.state.email).then(res => {

                    if (res.status == 200) {
                        let userInfo = toUserInformation(res)[0];
                        if (userInfo != undefined){
                            this.setState({
                                id: userInfo.ID,
                                givenName: userInfo.GivenName,
                                surName: userInfo.SurName
                            });
                        } else {
                            this.setState({
                                validation: validator.arrayValidation([{ id: 'email', name: 'користувача з даним Email не знайдено', type: 'CUSTOME_ERROR', value: ''}]).errorArr
                            });
                        }
                    } else {
                        this.setState({
                            validation: validator.arrayValidation([{ id: 'email', name: 'користувача з даним Email не знайдено', type: 'CUSTOME_ERROR', value: ''}]).errorArr
                        });
                    }
                });
            }

            this.setState({
                validation: validationResult.errorArr
            });
        }

    handleSave = () => {
        let validationResult = validator.arrayValidation([
            { id: 'email', name: 'Email корректно', type: 'EMPTY', value: !this.isValidEmail(this.state.email) ? '' : 'ok' },
            { id: 'givenName', name: 'Ім`я', type: 'EMPTY', value: this.state.givenName },
            { id: 'surName', name: 'Прізвище', type: 'EMPTY', value: this.state.surName },
            { id: 'company', name: 'Компанія', type: 'EMPTY', value: this.state.company }
        ]);

        if (validationResult.state == 'success') {
            this.setState({ loading: true });
            let Userinforation;
            let dataToSend

            if (this.state.id != '') {
                Userinforation = {
                    ID: this.state.id,
                    Mail: this.state.email,
                    GivenName: this.state.givenName,
                    SurName: this.state.surName,
                }

                dataToSend = {
                    User: Userinforation,
                    UserID: this.state.id,
                    CompanyID: this.state.companyID
                };
            } else {
                Userinforation = {
                    Mail: this.state.email,
                    GivenName: this.state.givenName,
                    SurName: this.state.surName,
                }

                dataToSend = {
                    User: Userinforation,
                    CompanyID: this.state.companyID
                };
            }

            api.apiCreatePetrolBoxClient(dataToSend).then(res => {
                this.setState({ loading: false });

                if (res.status == 201) {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створення PetrolBox.Clien користувача', description: 'PetrolBox.Clien користувач був успішно створений.', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створення PetrolBox.Clien користувача', description: 'PetrolBox.Clien користувач не був успішно створений. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Створення PetrolBox.Clien користувача', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Створення PetrolBox.Client користувача</h5>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Email</h5>
                                <div className="form-group">
                                    <div className='form-group-conteins'>
                                        <input
                                            placeholder="Email"
                                            type="tetx"
                                            className="form-control form-control-input-with"
                                            name="email"

                                            value={this.state.email}
                                            onChange={this.handlerInputEmailChange}
                                        />
                                        <button 
                                            onClick={this.handleInputFind} 
                                            className="btn btn-secondary form-control-button-with" 
                                            type="button">Знайти
                                        </button>
                                    </div>
                                    <div className="form-group-error">
                                        { renderError('email', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ім'я</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Ім'я"
                                        type="text"
                                        className="form-control"
                                        name="givenName"

                                        value={this.state.givenName}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('givenName', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Прізвище</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Прізвище"
                                        type="text"
                                        className="form-control"
                                        name="surName"

                                        value={this.state.surName}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('surName', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div className="form-group">
                                    <div>
                                        <Select
                                            placeholder="Виберіть компанію"
                                            value={this.state.company}
                                            onChange={this.handleCompany}
                                            options={this.state.companyList}
                                        />
                                        <div className="form-group-error">
                                            { renderError('company', this.state.validation) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PetrolBoxClientAddModal);