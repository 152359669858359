import { toPascalCase } from "../services/helpers";

const toSelectList = (nameField, idField) => (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item[idField], label: item[nameField] };
    })
}

export const toBarrelFuelList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.ID, label: item.TypeFuel.FuelName };
    })
}

export const toEmployeeList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.ID, label: `${item.Surname} ${item.Name} ${item.Patronymic}` };
    })
}

export const toStationList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.ID, label: `${item.City.Name}, ${item.Street}` };
    })
}

export const toStationAndAdressesList = (list) => {
    list.data.value = toPascalCase(list.data.value);
        return list.data.value.map((item, i) => {
            return { value: item.ID, name: item.Name, addresses: item.Addresses };
    })
}

export const toSelectCompanyWithTypePaymentList = (nameField, idFiled, TypePaymentFieldId) => (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return {value: item[idFiled], label: item[nameField], typePaymentID: item[TypePaymentFieldId] };
    })
}

export const toStationAndAdressesIDList = (list) => {
    list.data.value = toPascalCase(list.data.value);
        return list.data.value.map((item, i) => {
            return item.ID ;
    })
}

export const toStationAdressesList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.AddressID, label: `${item.CityName}, ${item.AddressStreet}` };
    })
}

export const toCompaniesList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.ID, label: item.CompanyName };
    })
}

export const toUsersAndCompanyList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { 
            ID: item.ID,
            DisplayName: item.User.DisplayName,
            GivenName: item.User.GivenName,
            SurName: item.User.SurName,
            Country: item.User.Country,
            UserGuidId: item.User.ID,
            Mail: item.User.Mail,
            CompanyID: item.Company.ID,
            CompanyName: item.Company.Name
        };
    })
}

export const toUserInformation = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { 
            ID: item.UserID,
            GivenName: item.GivenName,
            SurName: item.SurName,
        };
    })
}

export const toCompanyList = toSelectList('Name', 'ID');
export const toCompanyWithTypePaymentList = toSelectCompanyWithTypePaymentList('Name', 'ID', 'TypePaymentID')
export const toDepartmentList = toSelectList('Name', 'ID');
export const toFuelList = toSelectList('FuelName', 'ID');
export const toTypeTermList = toSelectList('TermName', 'ID');
export const toAuditTypeList = toSelectList('AuditFuelName', 'ID');
export const toTypePaymentList = toSelectList('PaymentName', 'ID');
export const toCardList = toSelectList('Name', 'ID');
export const toDiscountList = toSelectList('Name', 'ID');