import axios from 'axios';
import qs from 'qs';
import store from '../store';
import camelcaseKeys from 'camelcase-keys';

import config from '../config/config';

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) return Promise.reject(error);
        const {status} = error.response;
        if (status === UNAUTHORIZED) {
            localStorage.removeItem('selectedStation');
            store.getState().user.userCredentials.authContext.logout();
        }
        return Promise.reject(error);
    }
);

const url = config.url;

export default {

    apiGetCouponData({ skip, top, filter = [], order = [] } = {}) {
        const params = {
            $count: true,
            $top: top,
            $skip: skip,
            $select: 'isValid,isCovered,BalanceCapacity,capacity,created,id,partially,printed,valid,price',
            $expand: 'employee($expand=department($expand=company($select=name,id);$select=name,id);$select=name,Surname,Patronymic,id),TypeFuel($select=FuelName,id),TypeTerm($select=TermName,id)',
        };
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',')
        return sendRequest().get(
            `${url}/couponextendedset?${qs.stringify(params, { encodeValuesOnly: true })}`
        );
    },

    apiCreateCoupon({ copyCount, ...data }){
        return sendRequest().post(`${url}/couponset?copies=${copyCount || 1}`, data);
    },

    apiPatchCoupon(data){
        return sendRequest().patch(`${url}/couponset/${data.ID}`, data);
    },

    apiDeleteCoupon({ ID }){
        return sendRequest().delete(`${url}/couponset/${ID}`);
    },

    apiCreateReport(data){
       return sendRequest().get(`${config.urlReports}/${data.route}/${data.company}/${data.station}${data.fromDate != null ? "/" + data.fromDate : ''}${data.toDate != null ? "/" + data.toDate : ''}`, { responseType: 'arraybuffer', headers: {'Accept': 'application/vnd.ms-excel'} });
    },

    apiCreateLeftoversReport(data){
         return sendRequest().get(`${config.urlReports}/${data.route}/${data.company}${data.fromDate != null ? "/" + data.fromDate : ''}${data.toDate != null ? "/" + data.toDate : ''}`, { responseType: 'arraybuffer', headers: {'Accept': 'application/vnd.ms-excel'} });
    },

    apiCreateCouponQRCode(ID){
        return sendRequest().get(`${config.urlReports}/qrcode/coupon/${ID}`, { responseType: 'arraybuffer' })
            .then(response => Buffer.from(response.data, 'binary').toString('base64'));
    },

    apiCreatePeriodicReport(data){
        return sendRequest().get(`${config.urlReports}/${data.route}/${data.station}${data.fromDate != null ? "/" + data.fromDate : ''}${data.toDate != null ? "/" + data.toDate : ''}`, { responseType: 'arraybuffer', headers: {'Accept': 'application/vnd.ms-excel'} });
    },

    apiCreateProfitTransactionsReport(data){
       return sendRequest().get(`${config.urlReports}/${data.route}/${data.station}${data.fromDate != null ? "/" + data.fromDate : ''}${data.toDate != null ? "/" + data.toDate : ''}`, { responseType: 'arraybuffer', headers: {'Accept': 'application/vnd.ms-excel'} });
    },

    apiCreateCompanyRegistersReport(data){
        return sendRequest().get(`${config.urlReports}/${data.route}/${data.company}/${data.typePayment}${data.fromDate != null ? "/" + data.fromDate : ''}${data.toDate != null ? "/" + data.toDate : ''}`, { responseType: 'arraybuffer', headers: {'Accept': 'application/vnd.ms-excel'} });
    },

    apiCcreateClientCardOperationsReport(data){
        return sendRequest().get(`${config.urlReports}/${data.route}/${data.company}${data.fromDate != null ? "/" + data.fromDate : ''}${data.toDate != null ? "/" + data.toDate : ''}`, { responseType: 'arraybuffer', headers: {'Accept': 'application/vnd.ms-excel'} });
    },

    apiGetTypePaymentList(){
        return sendRequest().get(`${url}/TypePaymentSet?$orderby=PaymentName`);
    },

    apiAuditList(){
        return sendRequest().get(`${url}/TypeAuditFuelSet?$orderby=AuditFuelName`);
    },

    apiDiscountList(){
        return sendRequest().get(`${url}/TypeDiscountSet?$orderby=Name`);
    },

    apiOwnerList(){
        return sendRequest().get(`${url}/ownerset/`);
    },

    apiCreateOwner(data){
        return sendRequest().post(`${url}/ownerset`, data)
    },

    apiEditOwner(data){
        return sendRequest().patch(`${url}/ownerset/${data.ID}`, data);
    },

    apiEditPetrolStation(data){
        return sendRequest().put(`${url}/petrolstationset(${data.ID})`, data)
    },

    apiEditPetrolBoxClient(data){
        return sendRequest().put(`${url}/UserCompanySet/${data.ID}`, data);
    },

    apiDeleteOwner(data){
        return sendRequest().delete(`${url}/ownerset/${data.ID}`);
    },

    apiSynchronizationOwner(data){
        return sendRequest().post(`${url}/ownerset/${data.ID}/registeruployal`);
    },

    apiGetStationsList({ skip, top, filter = [], order = [] } = {}){

        const params = {
            $count: true,
            $expand: 'City',
            
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ')
        if (order.length) params.$orderby = order.join(',')
        return sendRequest().get(`${url}/addressset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetFuelList(){
        return sendRequest().get(`${url}/TypeFuelset?$orderby=FuelName`);
    },

    apiGetCompanyList(){
        return sendRequest().get(`${url}/companyset?$select=ID,Name&$orderby=Name`);
    },

    apiGetCompanyWithTypePaymentList(){
        return sendRequest().get(`${url}/companyset?$select=ID,Name,TypePaymentID&$orderby=Name`);
    },

    apiGetTypeTermList(data){
        return sendRequest().get(`${url}/typetermset?$orderby=TermName`, data);
    },

    /********************************************/

    apiGetDepartmentData({ ID, skip, top, filter = [], order = [] } = {}){
        const params = {
            $count: true,
            $expand: 'TypeTerm, Company',
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        const filterParams = [...filter]
        if (ID) filterParams.push(`CompanyID eq ${ID}`)
        if (filterParams.length) params.$filter = filterParams.join(' and ')
        if (order.length) params.$orderby = order.join(',')
        return sendRequest().get(`${url}/DepartmentSet?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiCreateDepartment(data){
        return sendRequest().post(`${url}/departmentset`, data);
    },

    apiEditDepartment(data){
        return sendRequest().patch(`${url}/departmentset/${data.ID}`, data);
    },

    apiDeleteDepartment(data){
        return sendRequest().delete(`${url}/departmentset/${data.ID}`);
    },

    /********************************************/

    apiGetCompanyData({ extend, top, skip, filter = [], order = [] } = {}){
        const params = {
            $count: true,
            $orderby: 'Name'
        }
        if (extend) params.$expand = extend.join(' ,');
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/CompanySet?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiCreateCompany(data){
        return sendRequest().post(`${url}/companyset`, data);
    },

    apiEditCompany(data, ID){
        return sendRequest().patch(`${url}/companyset/${ID}`, data);
    },

    apiDeleteCompany(ID){
        return sendRequest().delete(`${url}/companyset/${ID}`);
    },

    /********************************************/

    apiGetEmployeeData({ ID, skip, top, order = [], filter = [] } = {}){
        const params = {
            $count: true,
            $expand: 'Card, Department($expand=Company), TypeTerm',
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        const filterParams = [...filter]
        if (ID) filterParams.push(`DepartmentID eq ${ID}`)
        if (filterParams.length) params.$filter = filterParams.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/employeeset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiCreateEmployee(data){
        return sendRequest().post(`${url}/employeeset`, data);
    },

    apiEditEmployee(data){
        return sendRequest().patch(`${url}/employeeset/${data.ID}`, data);
    },

    apiDeleteEmployee(data){
        return sendRequest().delete(`${url}/employeeset/${data.ID}`);
    },

    /********************************************/

    apiGetRegisterData({ filter = [], order = [], skip, top } = {}){
        const params = {
            $count: true,
            $expand: 'Company($expand=TypeRegister), TypeFuel',
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');

        return sendRequest().get(`${url}/registerextendedset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetLoanData({ filter = [], order = [], skip, top } = {}){
        const params = {
            $count: true,
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');

        return sendRequest().get(`${url}/loancoverageset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiCreateLoan(data){
        return sendRequest().post(`${url}/loancoverageset`, data);
    },

    apiDeleteLoan(data){
        return sendRequest().delete(`${url}/loancoverageset/${data.ID}`, data);
    },

    apiCreateRegister(data){
        return sendRequest().post(`${url}/registerset`, data);
    },


    apiEditRegister(data){
        return sendRequest().patch(`${url}/registerset/${data.ID}`, data);
    },


    apiDeleteRegister(data){
        return sendRequest().delete(`${url}/registerset/${data.ID}`);
    },

    /********************************************/

    apiGetCompanyMovementData({ filter = [], order = [], skip, top } = []){
        const params = {
            $count: true,
            $expand: 'Company($expand=TypeRegister), TypeFuel',
            $skip: skip,
            $top: top,
        }
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/CompanyBalanceSet?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiCreateCompanyMovement(data){
        return sendRequest().post(`${url}/CompanyBalanceSet`, data);
    },


    apiEditCompanyMovement(data){
        return sendRequest().patch(`${url}/CompanyBalanceSet/${data.ID}`, data);
    },


    apiDeleteCompanyMovement(data){
        return sendRequest().delete(`${url}/CompanyBalanceSet/${data.ID}`);
    },

    /********************************************/

    apiGetRegisterAuditData({ filter = [], order = [], skip, top} = {}){
        const params = {
            $count: true,
            $expand: 'AuditFuel($expand=Barrel($expand=TypeFuel)), Employee($expand=Department($expand=Company))',
            $skip: skip,
            $top: top,
        }
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/AuditEmployeeSet?${qs.stringify(params, {encodeValuesOnly: true})}`);
    },

    apiCreateRegisterAudit(data){
        return sendRequest().post(`${url}/AuditEmployeeSet`, data);
    },


    apiEditRegisterAudit(data){
        return sendRequest().patch(`${url}/AuditEmployeeSet/${data.ID}`, data);
    },


    apiDeleteRegisterAudit(data){
        return sendRequest().delete(`${url}/AuditEmployeeSet/${data.ID}`);
    },

    /********************************************/

    apiGetBarrelData({ top, skip, filter = [], order = [] } = {}){
        const filterParams = [`addressid eq ${store.getState().user.station.ID}`, ...filter]
        const params = {
            $count: true,
            $expand: 'barrelbalance, TypeFuel',
            $filter: filterParams.join(' and ')
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (order.length) params.$orderby = order.join(',')
        return sendRequest().get(`${url}/barrelset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetRevenueData({ top, skip } = {}){
        const params = {
            $count: true,
            $expand: 'barrel($expand=typefuel)',
            $filter: `barrel/addressid eq ${store.getState().user.station.ID}`
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        return sendRequest().get(`${url}/revenueset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetCompanyRevenueCurrentData({ filter = [], order = [], top, skip } = {}){
        const params = {
            $count: true,
            $expand: 'Company',
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/companyrevenuecurrentset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetCompanyRevenueData({ filter = [], order = [], top, skip } = {}){
        const params = {
            $count: true,
            $expand: 'Company',
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/companyrevenueset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetCompanyPriceData({ top, skip, filter = [], order = [] } = {}){
        const params = {
            $count: true,
            $expand: 'Company, TypeFuel',
            $top: top,
            $skip: skip
        }
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/companypriceset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiCreateCompanyPrice(data){
        return sendRequest().post(`${url}/companypriceset`, data);
    },


    apiEditCompanyPrice(data){
        return sendRequest().patch(`${url}/companypriceset/${data.ID}`, data);
    },


    apiDeleteCompanyPrice(data){
        return sendRequest().delete(`${url}/companypriceset/${data.ID}`);
    },

    apiGetBarrelCurrentSnapshotData({ skip, top }){
        const params = {
            $count: true,
            $expand: 'barrel($expand=typefuel)',
            $skip: skip,
            $top: top,
            $filter: `barrel/addressid eq ${store.getState().user.station.ID}`,
        }
        return sendRequest().get(`${url}/barrelbalancecurrentsnapshotset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiEditBarrel(data){
        return sendRequest().patch(`${url}/barrelset/${data.ID}`, data);
    },

    /********************************************/

    apiGetTapData({ top, skip, filter = [], order = [] } = {}){
        const filterParams = [`barrel/addressid eq ${store.getState().user.station.ID}`, ...filter]
        const params = {
            $count: true,
            $expand: 'Barrel($expand=Typefuel), Station($select=Number)',
            $top: top,
            $skip: skip,
            $filter: filterParams.join(' and ')
        }
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/tapset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiEditTapData(data){
        return sendRequest().patch(`${url}/tapset/${data.ID}`, data);
    },

    /********************************************/

    apiGetCardData({ ID, top, skip, filter = [], order = [] } = {}){
        const params = {
            $count: true,
            $expand: 'employees($select=id)'
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        const filterParams = [...filter]
        if (ID) filterParams.push(`ID eq ${ID}`);
        if (filterParams.length) params.$filter = filterParams.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/cardset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiCreateCard(data){
        return sendRequest().post(`${url}/cardset`, camelcaseKeys(data));
    },

    apiCreateStation(data){
        return sendRequest().post(`${url}/petrolstationset`, data);
    },

    apiCreatePetrolBoxClient(data){

        const params = {
            $expand: 'User'
        }

        return sendRequest().post(`${url}/UserCompanySet?${qs.stringify(params, { encodeValuesOnly: true })}`, data);
    },

    apiEditCard(data){
        return sendRequest().patch(`${url}/cardset/${data.ID}`, data);
    },


    apiDeleteCard(data){
        return sendRequest().delete(`${url}/cardset/${data.ID}`);
    },

    apiDeleteStation({ID}){
        return sendRequest().delete(`${url}/petrolstationset/${ID}`);
    },

    apiDeletePetrolBoxClient(data){
        return sendRequest().delete(`${url}/UserCompanySet/${data.ID}`);
    },

    /********************************************/

    apiGetAuditData({ top, skip, filter = [], order = [] }){
        const filterParams = [`barrel/addressid eq ${store.getState().user.station.ID}`, ...filter]
        const params = {
            $count: true,
            $top: top,
            $skip: skip,
            $expand: 'barrel($expand=typefuel), typeauditfuel, typepayment',
            $filter: filterParams.join(' and ')

        };
        if (order.length) params.$orderby = order.join(',')
        return sendRequest().get(`${url}/auditfuelextendedset?${qs.stringify(params, { encodeValuesOnly: true })}`,);
    },

    apiGetMiniAuditData(filter = []){
        const filterParams = [`barrel/addressid eq ${store.getState().user.station.ID}`, ...filter]
        return sendRequest().get(`${url}/auditfuelextendedset/statistics?$filter=${filterParams.join(' and ')}`);
    },

    apiCreateFuelUncoming(data){
        return sendRequest().post(`${url}/auditfuelset`, data);
    },

    apiGetCashRegisterData({ top, skip, filter = [], order = [] }){
        const filterParams = [`cashcode/addressid eq ${store.getState().user.station.ID}`, ...filter]
        const params = {
            $count: true,
            $top: top,
            $skip: skip,
            $filter: filterParams.join(' and ')

        };
        if (order.length) params.$orderby = order.join(',')
        return sendRequest().get(`${url}/auditcashcodeextendedset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetOwnerData({top, skip, filter = [], }) {
        const filterParams = [...filter]

        const params = {
            $count: true,
            $top: top,
            $skip: skip,
        }

        if(filterParams.length > 0) params.$filter = filterParams.join(' and ')

        return sendRequest().get(`${url}/ownerset?${qs.stringify(params, {encodeValuesOnly: true})}`,);
    },

    apiGetStationData({top, skip, filter = [], }) {
        
        const filterParams = [...filter]

        const params = {
            $count: true,
            $top: top,
            $skip: skip,
            $expand: 'Addresses($expand=city)',
        }

        if(filterParams.length > 0) params.$filter = filterParams.join(' and ')

        return sendRequest().get(`${url}/petrolstationset?${qs.stringify(params, {encodeValuesOnly: true})}`,);
    },

    apiGetStationBocksesList() {
        const params = {
            $filter: 'PetrolStationID eq null',
            $expand: 'City',
            $select: 'Street,ID'
        }

        return sendRequest().get(`${url}/addressset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },


    apiGetStationList() {
        const params = {
            $expand: 'City',
            $select: 'Street,ID'
        }

        return sendRequest().get(`${url}/addressset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetStationByCompanyIDList(ID){ 
        return sendRequest().get(`${url}/companyset/${ID}/addressesbycompanyid`);
    },

    apiGetCompaniesByAddressIDList(ID){
        return sendRequest().get(`${url}/companyset/${ID}/companiesbyaddressid`);
    },

    apiGetStationBocksesByIDList({ ID }) {
        const params = {
            $filter: `PetrolStationID eq ${ID}`,
            $expand: 'City',
            $select: 'Street,ID'
        }

        return sendRequest().get(`${url}/addressset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetAdressesStationListID({ ID }) {
        const params = {
            $filter: `PetrolStationID eq ${ID}`,
            $select: 'ID'
        }

        return sendRequest().get(`${url}/addressset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },


    apiGetStationByID({ ID }){
        return sendRequest().get(`${url}/addressset/${ID}`);
    },

    apiGetClientsList({top, skip, filter = [], }) {

        const filterParams = [...filter]; 

        const params = {
            $count: true,
            $top: top,
            $skip: skip, 
            $expand: 'User, Company',
        }

        if(filterParams.length > 0) params.$filter = filterParams.join(' and ')

        return sendRequest().get(`${url}/UserCompanySet?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetUserInformation(mail) {
        return sendRequest().get(`${url}/petrolboxclientuserset?$filter=Mail%20eq%20\'${mail}\'`);
    }
};

function sendRequest(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().user.userCredentials.token}`;
    return axios;
}
