import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../api/index';
import { initData, login } from '../actions';
import { toTypeTermList } from '../helpers/toLists';

import { myMSALObj, signIn } from '../helpers/msalAuth2'

async function loadConfig(props) {
    const accessToken = await signIn();
    if (!accessToken) return

    props.dispatchLogin({
        userCredentials: {
            token: accessToken, authContext: myMSALObj
        }
    });

    const typeTermList = await api.apiGetTypeTermList().then(toTypeTermList);

    props.dispatchGetInitData({
        typeTermList,
    });
}

const Auth = (props) => {
    useEffect(() => {
        loadConfig(props);
    }, []);
    return props.auth === null ? <div/> : props.children
}

const mapStateToProps = (state) => {
    return {
        auth: state.user.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetInitData: (data) => dispatch(initData(data)),
        dispatchLogin: (data) => dispatch(login(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
