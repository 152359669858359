import React, { Component } from 'react';

import api from "../../../../../../api";

import { normalizeNumberMaxDecimals, renderError } from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import {hideModal, showModal} from "../../../../../../actions/modals";
import {connect} from "react-redux";
import Spiner from "../../../../../common/Spiner";

class CardModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            closed: false,
            name: '',
            serialNumber: '',

            validation: [],
            callback: () => {},
        };
    }

    componentDidMount() {
        this.setState({
            callback: this.props.propsData.callback
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'serialNumber', name: 'serial number', type: 'EMPTY', value: this.state.serialNumber },
            { id: 'name', name: 'name', type: 'EMPTY', value: this.state.name },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                Closed: this.state.closed,
                SerialNumber: this.state.serialNumber,
                Name: this.state.name
            };

            api.apiCreateCard(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 201){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створення картки', description: 'Картка була успішно створена', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створення картки', description: 'Картка не була створена. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Створення картки', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Створити картку</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Серійний номер</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть серійний номер"
                                        type="text"
                                        className="form-control"
                                        name="serialNumber"

                                        value={this.state.serialNumber}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('serialNumber', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Назва</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть назву"
                                        type="text"
                                        className="form-control"
                                        name="name"

                                        value={this.state.name}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('name', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Заблоковано</h5>
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="closed"

                                            value={this.state.closed}
                                            onChange={this.handleInputChange}
                                            checked={this.state.closed}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CardModal);
