import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import {normalizeNumberMaxDecimals, renderError} from "../../../../services/helpers";
import validator from "../../../../services/DataValidator";
import api from "../../../../api";
import { hideModal, showModal } from "../../../../actions/modals";
import Spiner from "../../../common/Spiner";
import {toFuelList} from "../../../../helpers/toLists";

class EditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                fuelList: []
            },

            ID: null,
            addressID: '',
            number: '',
            fuelType: '',
            price: '',
            balanceAmount: '',
            lowCapacity: '',
            warningCapacity: '',

            callback: () => {},

            validation: []
        };
    }

    async componentDidMount(){
        this.setState({
            data: {
                fuelList: await api.apiGetFuelList().then(toFuelList)
            },

            ID: this.props.propsData.ID,
            addressID: this.props.propsData.AddressID,
            number: this.props.propsData.Number,
            fuelType: this.props.propsData.TypeFuel === null ? '' : {
                value: this.props.propsData.TypeFuel.ID, label: this.props.propsData.TypeFuel.FuelName
            },
            price: this.props.propsData.Price,
            balanceAmount: this.props.propsData.BarrelBalance.BalanceAmount,
            lowCapacity: this.props.propsData.LowCapacity,
            warningCapacity: this.props.propsData.WarningCapacity,

            callback: this.props.propsData.callback
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleCompany = (selectedOption) => {
        this.setState({
            company: selectedOption
        });
    };

    handleFuelType = (selectedOption) => {
        this.setState({
            fuelType: selectedOption
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'price', name: 'Ціна', type: 'EMPTY', value: this.state.price },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                ID: +this.state.ID,
                AddressID: +this.state.addressID,
                TypeFuelID: +this.state.fuelType.value,
                Price: +this.state.price,
                Number: +this.state.number,
                // balanceAmount: this.state.BalanceAmount,
                LowCapacity: +this.state.lowCapacity,
                WarningCapacity: +this.state.warningCapacity,
            };

            api.apiEditBarrel(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 204){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати запис', description: 'Запис був успішно відредагований', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати запис', description: 'Запис не був відредагований. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати запис', description: error.response.data.message, type: false });
            });


        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати запис</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Тип палива</h5>
                                <div>
                                    <Select
                                        value={this.state.fuelType}
                                        onChange={this.handleFuelType}
                                        options={this.state.data.fuelList}
                                        isDisabled={true}
                                    />
                                    <div className="form-group-error">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ціна, грн</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть ціну"
                                        type="text"
                                        className="form-control"
                                        name="price"
                                        data-type="number"

                                        value={this.state.price}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('price', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Фактичний об'єм, л</h5>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="balanceAmount"
                                        data-type="number"

                                        readOnly
                                        value={this.state.balanceAmount}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('balanceAmount', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Нижній граничний об'єм, л</h5>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lowCapacity"
                                        data-type="number"

                                        value={this.state.lowCapacity}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('lowCapacity', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Критичний об'єм, л</h5>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="warningCapacity"
                                        data-type="number"

                                        value={this.state.warningCapacity}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('warningCapacity', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
