import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from './Table';
import Station from '../elements/Station';
import Sidebar from '../cod/Sidebar';
import Wrapper from '../index';
import api from '../../../api/index';
import {toPascalCase} from "../../../services/helpers";
import getCount from "../../../helpers/getCount";
import sortConverter from "../../../helpers/sortConverter";
import { setFilterData } from '../../../actions/filtersReducers';

class Index extends Component {
    constructor(props){
        super(props);
        this.state = {
            filtered: [],
            sorted: [],
            count: 0,
            pageSize: 10,
            page: 0,
            getDataStatus: 'success',
            array: []
        };
    }

    static filterType = 'STATIONS'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.filtered, Index.filterType));
    }

    async componentDidMount(){
        this.setState({
            filtered: this.props.filtersReducer[Index.filterType] || this.state.filtered,
        }, this.handleGetData)
    }

    handleChangePageSize = (pageSize) => {
        this.setState({ page: 0, pageSize }, this.handleGetData)
    }

    handleChangePage = (page) => {
        this.setState({ page }, this.handleGetData)
    }

    handleOnFilterChange = (filtered = []) => {
        this.setState({ filtered }, this.handleGetData)
    }
    
    handleClearFilter = () => {
        this.setState({ filtered: [] }, this.handleGetData)
    }

    handleOnSortChange = (sorted = []) => {
        this.setState({ sorted }, this.handleGetData)
    }

    handleGetData = async () => {
        this.setState({ getDataStatus: 'pending' });
        const filter = this.state.filtered.map(({ id, value }) => `contains(${id}, '${value}')`);
        const data = {
            order: sortConverter(this.state.sorted),
            filter,
            top: this.state.pageSize,
            skip: this.state.pageSize * this.state.page
        };
        let res = await api.apiGetStationsList(data);
        this.setState({
            array: toPascalCase(res.data.value),
            getDataStatus: 'success',
            count: getCount(res)
        });
    };

    render(){
        return (
            <Wrapper>
                <div className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <Station />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Table
                                handleOnFilterChange={this.handleOnFilterChange}
                                handleChangePageSize={this.handleChangePageSize}
                                handleChangePage={this.handleChangePage}
                                handleOnSortChange={this.handleOnSortChange}
                                count={this.state.count}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                propsArray={this.state.array}
                                propsGetDataStatus={this.state.getDataStatus}
                                filtered={this.state.filtered}
                                sorted={this.state.sorted}
                            />
                        </div>
                    </div>
                    <Sidebar
                        showNavigation={false}
                        onClear={this.handleClearFilter}
                    />
                </div>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
