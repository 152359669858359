import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Auth from '../../etc/Auth';

const DashboardRoute = ({ stationCheck, component: Component, ...rest }) => (
    <Auth>
        <Route
          {...rest}
          children={stationCheck === true ? <Component /> : <Redirect to='/' />}
        />
    </Auth>
);

const mapStateToProps = (state) => {
    return {
        stationCheck: state.user.stationCheck
    }
};

export default connect(mapStateToProps)(DashboardRoute);
