import React, { Component } from 'react';
import AuditTable from './items/AuditTable';
import MiniAuditTable from  './items/MiniAuditTable';
import { connect } from 'react-redux';
import api from '../../../api';
import { showModal } from "../../../actions/modals";
import Sidebar from '../cod/Sidebar';
import Wrapper from '../index';
import Station from '../elements/Station';
import {toPascalCase} from "../../../services/helpers";
import {toAuditTypeList, toFuelList, toTypePaymentList, toDiscountList} from "../../../helpers/toLists";
import getCount from "../../../helpers/getCount";
import sortConverter from "../../../helpers/sortConverter";
import { selectStringConverter, selectNumberConverter, booleanConverter, stringConverter, nullOrNotConverter, dateConverter, numberConverter, createFilter } from "../../../helpers/filterConverters";
import { setFilterData } from '../../../actions/filtersReducers';

const checkType = [
    { value: '1', label: 'З чеком' },
    { value: '2', label: 'Еко режим' }
];

const convertersMap = {
    TypeAuditFuelID: selectNumberConverter,
    'Barrel/TypeFuelID': selectNumberConverter,
    CompanyName: stringConverter,
    CompanyPaymentName: selectStringConverter,
    FuelDate: dateConverter,
    AmountCash: numberConverter,
    Amount: numberConverter,
    Price: numberConverter,
    Capacity: numberConverter,
    ReceiptNumber: nullOrNotConverter,
    TypePaymentID: selectNumberConverter,
    AutoRegister: booleanConverter,
    Registered: booleanConverter,
    DiscountAmount: numberConverter,
    TypeDiscountID: selectNumberConverter,
    DiscountName: stringConverter

}

class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            count: 0,
            pageSize: 10,
            page: 0,
            data: {
                auditTypeList: [],
                fuelList: [],
                typePaymentList: [],
                discountTypeList: [],
            },
            filtered: [
                { 
                    id: 'FuelDate', value: {
                        from: new Date(new Date().setHours(0,0,0,0)),
                        to: new Date(new Date().setHours(23,59,59,0))
                    }
                 }
            ],
            sorted: [{ id: 'FuelDate', desc: true }],
            getDataStatus: 'success',
            auditArr: [],
            sortAuditArr: [],
            miniAuditArray: [],
            filter: false
        };
    }

    static filterType = 'AUDIT'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.filtered, Index.filterType));
    }
    async componentDidMount(){

        const [
            auditTypeList,
            fuelList,
            typePaymentList,
            discountTypeList,
        ] = await Promise.all([
            api.apiAuditList().then(toAuditTypeList),
            api.apiGetFuelList().then(toFuelList),
            api.apiGetTypePaymentList().then(toTypePaymentList),
            api.apiDiscountList().then(toDiscountList),
        ]);

        this.setState({
            data: {
                auditTypeList,
                fuelList,
                typePaymentList,
                discountTypeList,
            },
            filtered: this.props.filtersReducer[Index.filterType] || this.state.filtered,
        }, () => {
            this.handleFilter();
        });


    }

    handleOnFilterChange = (filtered = []) => {
        this.setState({ filtered }, this.handleFilter)
    }

    handleOnSortChange = (sorted = []) => {
        this.setState({ sorted }, this.handleFilter);
    }

    handleClearFilter = async () => {
        this.setState({
            page: 0,
            filtered: [
                { 
                    id: 'FuelDate', value: {
                        from: new Date(new Date().setHours(0,0,0,0)),
                        to: new Date(new Date().setHours(23,59,59,0))
                    }
                 }
            ],
            sortAuditArr: []
        }, this.handleFilter);
    };

    handleChangePageSize = (pageSize) => {
        this.setState({ page: 0, pageSize }, () => this.handleFilter())
    }

    handleChangePage = (page) => {
        this.setState({ page }, () => this.handleFilter())
    }

    handleFilter = async () => {
        const filter = createFilter(this.state.filtered, convertersMap);
        const data = {
            filter,
            order: sortConverter(this.state.sorted),
            top: this.state.pageSize,
            skip: this.state.pageSize * this.state.page
        };

        this.setState({
            getDataStatus: 'pending'
        });

        let [auditRes, miniAuditRes] = await Promise.all([
            api.apiGetAuditData(data),
            api.apiGetMiniAuditData(filter)
        ]);
        this.setState({
            getDataStatus: 'success',
            count: getCount(auditRes)
        });
        const sortAuditArr = toPascalCase(auditRes.data.value);
        this.setState({
            sortAuditArr,
            miniAuditArray: toPascalCase(miniAuditRes.data.value)
        });
    };

    render(){
        return (
        <Wrapper>
            <div className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <Station />
                    </div>
                </div>
                <AuditTable
                    propsGetDataStatus={this.state.getDataStatus}
                    propsAuditArr={this.state.sortAuditArr}
                    handleChangePageSize={this.handleChangePageSize}
                    handleChangePage={this.handleChangePage}
                    count={this.state.count}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    data={this.state.data}
                    createClearField={this.createClearField}
                    handleOnFilterChange={this.handleOnFilterChange}
                    handleOnSortChange={this.handleOnSortChange}
                    filtered={this.state.filtered}
                    sorted={this.state.sorted}
                />
                <MiniAuditTable propsMiniAuditArray={this.state.miniAuditArray} />
                <Sidebar
                    propsOnCreate={() => this.props.dispatchShowModal('FUEL_COMING_MODAL', { callback: this.handleFilter })}
                    showNavigation={false}
                    onClear={this.handleClearFilter}
                />
            </div>
        </Wrapper>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
