import React, { Component } from 'react';

import { normalizeNumberMaxDecimals } from "../../../../services/helpers";
import ClearButton from "../../../common/ClearButton";
import { connect } from "react-redux";


class Filter extends Component{

    constructor(props){
        super(props);

        this.state = {
            description: '',
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleClearFilter = () => {
        this.setState({
            description: '',
        });

        this.props.handleSetState('reportGroupsToView', this.props.reportGroups);
    };

    handleClearDescription = () => {
        this.setState({
            description: '',
        });
    }

    handleSort = async () => {

        let array = JSON.parse(JSON.stringify(this.props.reportGroups))

        array = array.map(item => {
            let tmpArray = [];

            for(let report of item.reports){
                if(report.description.search(this.state.description) !== -1)
                    tmpArray.push(report)
            }

            return {
                ...item,
                reports: tmpArray
            }
        });

        this.props.handleSetState('reportGroupsToView', array)
    };

    render(){

        return (

            <div className={`${this.props.filter ? 'active': ''} filter`}>
                <div className="df filter-top" onClick={this.props.onFilter}>
                    <h3>Фільтр</h3>
                    {
                        this.props.filter ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>
                    }
                </div>
                <div className="df ai-center">
                    <h5>Опис</h5>
                    <div className="form-group form-group_margin">
                        <input
                            placeholder="Введіть опис ..."
                            type="text"
                            className="form-control"
                            name="description"

                            value={this.state.description}
                            onChange={this.handleInputChange}
                        />
                        <ClearButton onClick={this.handleClearDescription}/>
                    </div>
                </div>
                <div className="filter__control">
                    <button onClick={this.handleSort} type="button" className="btn btn-primary">Встановити фільтр</button>
                    <button onClick={this.handleClearFilter} type="button" className="btn btn-secondary">Очистити фільтр</button>
                    <button onClick={this.props.onFilter} type="button" className="btn btn-info">Згорнути фільтр</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
