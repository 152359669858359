import React from 'react';
import { connect } from 'react-redux';

const Station = ({ station }) => !station ? null : (
  <div  className="text-align-center">
      {
          station ? <h3>AЗС {station.City.Name + ', ' + station.Street}</h3> : ''
      }
  </div>
);

const mapStateToProps = (state) => {
    return {
        station: state.user.station
    }
};

export default connect(mapStateToProps, {})(Station);
