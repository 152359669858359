import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import DashboardRoute from './dashboard/DashboardRoute'
import Stations from './dashboard/stations/index';
import Audit from './dashboard/audit';
import CashRegister from './dashboard/cashRegister';
import Card from './dashboard/cod/items/card/index';
import Coupon from './dashboard/cod/items/coupon/index';
import Company from './dashboard/cod/items/company/index';
import Department from './dashboard/cod/items/department/index';
import Employee from './dashboard/cod/items/employee/index';
import Register from './dashboard/cod/items/register/index';
import CompanyMovement from './dashboard/cod/items/companyMovement/index';
import RegisterAudit from './dashboard/cod/items/registerAudit/index';
import Barrel from './dashboard/barrel/index';
import CodPrice from './dashboard/cod/items/price/index';
import TRK from './dashboard/trk/index';
import Reports from './dashboard/reports/index';
import Owners from './dashboard/owners/index';
import PetrolStations from './dashboard/petrolStations/index';
import HomeRoute from "./home/HomeRoute";
import Layout from './general/Layout';
import Administration from './dashboard/administration/petrolBoxClient/index'

const App = () => (
  <Layout>
    <Switch>
      <HomeRoute exact path="/" component={Stations}/>
      <DashboardRoute exact path="/audit" component={Audit} />
      <DashboardRoute exact path="/cash-register" component={CashRegister} />
      <HomeRoute exact path="/cod/card" component={Card} />
      <HomeRoute exact path="/cod/company" component={Company} />
      <HomeRoute exact path="/cod/department" component={Department} />
      <HomeRoute exact path="/cod/employee" component={Employee} />
      <HomeRoute exact path="/cod/register" component={Register} />
      <HomeRoute exact path="/cod/price" component={CodPrice} />
      <HomeRoute exact path="/cod/company-movement" component={CompanyMovement} />
      <HomeRoute exact path="/cod/register-audit" component={RegisterAudit} />
      <HomeRoute exact path="/cod/coupon" component={Coupon} />
      <DashboardRoute exact path="/barrel" component={Barrel} />
      <DashboardRoute exact path="/trk" component={TRK} />
      <HomeRoute exact path="/reports" component={Reports} />
      <HomeRoute exact path="/owners" component={Owners} />
      <HomeRoute exact path="/stations" component={PetrolStations} />
      <HomeRoute exact path="/administration" component={Administration} />
      <Redirect to='/audit'/>
    </Switch>
  </Layout>

);

export default App;
