import React, { Component } from 'react';
import { connect } from "react-redux";

import Select from "../../../common/Select";
import Spiner from "../../../common/Spiner";

import api from '../../../../api/index';
import { hideModal, showModal } from "../../../../actions/modals";
import { toStationAndAdressesIDList, toStationList } from "../../../../helpers/toLists";
import validator from "../../../../services/DataValidator";
import { normalizeNumberMaxDecimals, renderError } from "../../../../services/helpers";

class StationEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            name: '',
            id: '',
            box: '',
            boxDelete: '',
            addresses: [],
            stationBoxList: [],
            stationComboBox: [],
            validation: [],
            callback: () => { },
        };
    }

    async componentDidMount() {
        this.setState({
            ID: this.props.propsData.ID,
            name: this.props.propsData.Name,
            callback: this.props.propsData.callback
        });

        let reqData = {
            ID: this.props.propsData.ID
        };

        const [
            stationComboBox,
            stationBoxList,
            addresses
        ] = await Promise.all([
            await api.apiGetStationBocksesList().then(toStationList),
            await api.apiGetStationBocksesByIDList(reqData).then(toStationList),
            await api.apiGetAdressesStationListID(reqData).then(toStationAndAdressesIDList),
        ])

        this.setState({
            stationComboBox,
            stationBoxList,
            addresses
        })
    }
    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (event.target.dataset.type == 'number') {
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleSave = () => {
        let validationResult = validator.arrayValidation([
            { id: 'name', name: 'Назва', type: 'EMPTY', value: this.state.name },
        ]);

        if (validationResult.state == 'success') {
            this.setState({ loading: true });

            let dataToSend = {
                ID: this.state.ID,
                Addresses: this.state.addresses.map((item) => { return { ID: item } }),
                Name: this.state.name,
            };

            api.apiEditPetrolStation(dataToSend).then(res => {
                this.setState({ loading: false });
                if (res.status == 204) {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування АЗС', description: 'АЗС була успішно змінена', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування АЗС', description: 'АЗС не була змінена. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування АЗС', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    handleBocks = (selectedOption) => {
        this.setState({
            box: selectedOption
        });
    }

    handlAddBocks = () => {
        let validationResult = validator.arrayValidation([
            { id: 'box', name: 'Бокс', type: 'EMPTY', value: this.state.box },
        ]);

        if (validationResult.state == 'success') {
            if (this.state.box !== undefined && !this.state.stationBoxList.includes(this.state.box)) {
                const arrayRes = this.state.stationBoxList;
                const arrayAddresses = this.state.addresses;
                arrayRes.push(this.state.box);
                arrayAddresses.push(this.state.box.value)
                this.setState({
                    stationBoxList: arrayRes,
                    addresses: arrayAddresses,
                    stationComboBox: this.state.stationComboBox.filter(a => a.value !== this.state.box.value),
                    box: ''
                })
            }
        }

        this.setState({
            validation: validationResult.errorArr
        });
    }

    handlDeleteBocks = () => {
        let validationResult = validator.arrayValidation([
            { id: 'boxDelete', name: 'Бокси', type: 'SELECT_OPTION_EMPTY', value: this.state.boxDelete },
        ]);

        if (validationResult.state == 'success' && this.state.stationBoxList.map((index) => index.value === this.state.boxDelete)) {
            const stationComboBoxRes = this.state.stationComboBox;
            stationComboBoxRes.push(this.state.stationBoxList.find(sb => sb.value === parseInt(this.state.boxDelete)))

            this.setState({
                stationBoxList: this.state.stationBoxList.filter(sb => sb.value !== parseInt(this.state.boxDelete)),
                addresses: this.state.addresses.filter(a => a !== parseInt(this.state.boxDelete)),
                stationComboBox: stationComboBoxRes
            })
        }

        this.setState({
            validation: validationResult.errorArr
        });
    }

    handleBoxDelete = (selectedOption) => {
        this.setState({
            boxDelete: selectedOption
        });
    }

    handlerSelect = () => { }

    render() {
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати АЗС</h5>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Назва</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть назву"
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.handleInputChange}/>
                                    <div className="form-group-error">
                                        {renderError('name', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Бокси</h5>
                                <div className="form-group">
                                    <select className="form-control"
                                        multiple
                                        name="list-box"
                                        onChange={(e) => this.handleBoxDelete(Array.from(e.target.selectedOptions, (option) => option.value))}>
                                        {this.state.stationBoxList.map((item) => (<option key={item.value} value={item.value} >{item.label}</option>))}
                                    </select>
                                    <div className="form-group-error">
                                        {renderError('boxDelete', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5 />
                                <div className="form-group">
                                    <div>
                                        <Select
                                            value={this.state.box}
                                            onChange={this.handleBocks}
                                            options={this.state.stationComboBox}
                                            placeholder="Виберіть бокс" />
                                        <div className="form-group-error">
                                            {renderError('box', this.state.validation)}
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5 />
                                <div className="form-group">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={this.handlAddBocks}
                                        type="button">
                                        <span>Додати бокс</span>
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={this.handlDeleteBocks}
                                        style={{
                                            marginLeft: 5
                                        }}
                                        type="button">
                                        <span>Видалити бокс</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary">
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StationEditModal);
