import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {normalizeNumberMaxDecimals, renderError} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import api from "../../../../../../api";
import {hideModal, showModal} from "../../../../../../actions/modals";
import Spiner from "../../../../../common/Spiner";
import {toCompanyList} from "../../../../../../helpers/toLists";

class DepartmentEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ID: '',
            name: '',
            limit: '',
            company: '',
            typeTerm: '',
            closed: false,
            data: {
                companyList: []
            },
            callback: () => {},

            validation: []
        };
    }

    async componentDidMount(){
        this.setState({
            data: {
                companyList: await api.apiGetCompanyList().then(toCompanyList),
            }
        });
        if(typeof this.props.propsData != 'undefined'){
            this.setState({
                ID: this.props.propsData.ID,
                closed: this.props.propsData.Closed,
                name: this.props.propsData.Name,
                limit: this.props.propsData.Limit === null ? '0' : this.props.propsData.Limit,
                company: {
                    value: this.props.propsData.Company.ID, label: this.props.propsData.Company.Name
                },
                typeTerm: this.props.propsData.TypeTermID !== null ? {
                    value: this.props.propsData.TypeTerm.ID, label: this.props.propsData.TypeTerm.TermName
                } : '',
                callback: this.props.propsData.callback
            })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleCompany = (selectedOption) => {
        this.setState({
            company: selectedOption
        });
    };

    handleTypeTerm = (selectedOption) => {
        this.setState({
            typeTerm: selectedOption
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'name', name: 'Назва', type: 'EMPTY', value: this.state.name },
            // { id: 'limit', name: 'Кількість ліміту', type: 'EMPTY', value: this.state.limit },
            { id: 'company', name: 'Компанія', type: 'EMPTY', value: this.state.company },
            // { id: 'typeTerm', name: 'Термін ліміту', type: 'EMPTY', value: this.state.typeTerm },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });

            let dataToSend = {
                ID: this.state.ID,
                CompanyID: +this.state.company.value,
                Closed: this.state.closed,
                Name: this.state.name,
                Limit: !(+this.state.limit) ? null : +this.state.limit,
                TypeTermID: this.state.typeTerm == null ? null : +this.state.typeTerm.value,
            };

            api.apiEditDepartment(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 204){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати відділ', description: 'Відділ був успішно відредагований', type: true });
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати відділ', description: 'Відділ не був відредагований. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати відділ', description: error.response.data.message, type: false });
            });

        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати відділ</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={this.state.data.companyList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Назва</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть назву"
                                        type="text"
                                        className="form-control"
                                        name="name"

                                        value={this.state.name}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('name', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Кількість ліміту</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть кількість ліміту"
                                        type="text"
                                        className="form-control"
                                        name="limit"
                                        data-type="number"

                                        value={this.state.limit}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('limit', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Термін ліміту</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть термін ліміту"
                                        value={this.state.typeTerm}
                                        onChange={this.handleTypeTerm}
                                        options={this.props.typeTerm}
                                        isClearable
                                    />
                                    <div className="form-group-error">
                                        {renderError('typeTerm', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div><div className="col-lg-12">
                        <div className="modal__row">
                            <h5>Заблоковано</h5>
                            <label>
                                <input
                                    type="checkbox"
                                    name="closed"

                                    value={this.state.closed}
                                    onChange={this.handleInputChange}
                                    checked={this.state.closed}
                                />
                                <div className="form-group-error">
                                    {renderError('closed', this.state.validation)}
                                </div>
                            </label>
                        </div>
                    </div>

                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentEditModal);
