import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from "../../../../../../actions/modals";
import api from '../../../../../../api/index';
import Spiner from "../../../../../common/Spiner";

class CardDeleteModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            ID: '',
            name: '',

            callback: () => {},
        };
    }

    componentDidMount(){
        if(typeof this.props.propsData != 'undefined'){
            this.setState({
                ID: this.props.propsData.ID,
                name: this.props.propsData.Name,

                callback: this.props.propsData.callback
            })
        }
    }

    handleDelete = () => {

        let reqData = {
            ID: this.state.ID
        };

        this.setState({ loading: true });
        api.apiDeleteCard(reqData).then(res => {
            this.setState({ loading: false });
            if(res.status == 200){
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Видалення картки', description: 'Картка "' + this.state.name + '" була успішно видалена', type: true })
                this.state.callback();
            } else {
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Видалення картки', description: 'Картка "' + this.state.name + '" не була видалена. Зверніться до адміністратора', type: false })
            }
        }).catch(error => {
            this.setState({ loading: false });
            this.props.dispatchHideModal();
            this.props.dispatchShowModal('INFO_MODAL', { title: 'Видалення картки', description: error.response.data.message, type: false });
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Видалити картку</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                Ви дійсно хочете видалити картку "{ this.state.name }"
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleDelete}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Так</span>
                                        : <span>Видаляємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDeleteModal);
