import React, { Component } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';

import Spiner from "../../../../common/Spiner";

import api from '../../../../../api/index';
import { hideModal, showModal } from "../../../../../actions/modals";
import validator from "../../../../../services/DataValidator";
import { normalizeNumberMaxDecimals, renderError } from "../../../../../services/helpers";
import {toCompanyList} from "../../../../../helpers/toLists";

class PetrolBoxClientEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: '',
            email: '',
            givenName: '',
            surName: '',
            companyID: '',
            company: '',
            country: '',
            userGuidId: '',
            companyList: [],
            validation: [],
            callback: () => { }
        };
    }

    async componentDidMount() {
        this.setState({
            id: this.props.propsData.ID,
            email: this.props.propsData.Mail,
            givenName: this.props.propsData.GivenName,
            surName: this.props.propsData.SurName,
            userGuidId: this.props.propsData.UserGuidId,
            companyID: this.props.propsData.CompanyID,
            company: { value: this.props.propsData.CompanyID, label: this.props.propsData.CompanyName },
            country: this.props.propsData.Country,
            callback: this.props.propsData.callback
        });

        this.setState({
            companyList: await api.apiGetCompanyList().then(toCompanyList)
        })
    }

    isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        return emailRegex.test(email);
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (event.target.dataset.type == 'number') {
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleCompany = (selectedOption) => {
        this.setState({
            company: selectedOption
        });
    };

    handleSave = () => {
        let validationResult = validator.arrayValidation([
            { id: 'email', name: 'email', type: 'EMPTY', value: !this.isValidEmail(this.state.email) ? '' : 'ok' },
            { id: 'givenName', name: 'Ім`я', type: 'EMPTY', value: this.state.givenName },
            { id: 'surName', name: 'Прізвище', type: 'EMPTY', value: this.state.surName },
        ]);

        if (validationResult.state == 'success' && this.state.company.value != undefined) {
            this.setState({ loading: true });

            let Userinforation = {
                ID: this.state.userGuidId,
                Mail: this.state.email,
                GivenName: this.state.givenName,
                SurName: this.state.surName
            }

            let dataToSend = {
                ID: this.state.id,
                User: Userinforation,
                UserID: this.state.userGuidId,
                CompanyID: this.state.company.value
            };

            api.apiEditPetrolBoxClient(dataToSend).then(res => {
                this.setState({ loading: false });

                if (res.status == 204) {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування PetrolBox.Clien користувача', description: 'PetrolBox.Clien користувач був успішно змінений', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування PetrolBox.Clien користувача', description: 'PetrolBox.Clien користувач не був успішно змінений. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагування PetrolBox.Clien користувача', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагування PetrolBox.Client користувача</h5>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Email</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Email"
                                        disabled
                                        type="tetx"
                                        className="form-control"
                                        name="email"

                                        value={this.state.email != undefined ? this.state.email : ''}
                                    />
                                    <div className="form-group-error">
                                        { renderError('email', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ім'я</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Ім'я"
                                        disabled
                                        type="text"
                                        className="form-control"
                                        name="givenName"

                                        value={this.state.givenName}
                                    />
                                    <div className="form-group-error">
                                        { renderError('givenName', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Прізвище</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Прізвище"
                                        disabled
                                        type="text"
                                        className="form-control"
                                        name="surName"

                                        value={this.state.surName}
                                    />
                                    <div className="form-group-error">
                                        { renderError('surName', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть компанію"
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={this.state.companyList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PetrolBoxClientEditModal);