import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactTable from "react-table";

import { setStation } from "../../../actions";
import {clearFilters} from "../../../actions/filtersReducers";
import { selectDefTableSize } from '../../../selectors/screenSelectors';
import StringInput from '../../common/table/StringInput';

class Table extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        const { propsArray = [], propsGetDataStatus, defaultPageSize } = this.props;
        const pendingClass = !propsArray.length && propsGetDataStatus === 'pending' ? 'table-main-wrap_loading' : '';
        return (
            <div className={`table-main-wrap table-main-wrap_register mt-50 ${pendingClass}`}>
                <div className="table-header">Інформація про бокси</div>
                <ReactTable
                    filtered={this.props.filtered}
                    sorted={this.props.sorted}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.setState({
                                        selected: rowInfo.index
                                    });
                                    this.props.dispatchClearFilters();
                                    this.props.setStation({station: rowInfo.original, stationCheck: true});
                                    localStorage.setItem('selectedStation', JSON.stringify(rowInfo.original));
                                    this.props.history.push('/audit');
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.state.selected ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.state.selected ? 'white' : 'black',
                                    cursor: 'pointer'
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={propsArray}
                    columns={[
                        { Header: "Місто", accessor: "City.Name", id: "City/Name", Filter: StringInput },
                        { Header: "Вулиця", accessor: "Street", Filter: StringInput }
                    ]}
                    defaultPageSize={defaultPageSize}
                    className=" -highlight"
                    pageSize={this.props.pageSize}
                    page={this.props.page}
                    onPageSizeChange={this.props.handleChangePageSize}
                    onPageChange={this.props.handleChangePage}
                    manual={true}
                    filterable
                    sortable
                    multiSort
                    pages={Math.ceil(this.props.count / this.props.pageSize)}
                    onFilteredChange={this.props.handleOnFilterChange}
                    onSortedChange={this.props.handleOnSortChange}
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                />
                <div className="loading" />
            </div>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStation: (data) => dispatch(setStation(data)),
        dispatchClearFilters: () => dispatch(clearFilters())
    }
};
const mapStateToProps = state => ({
    defaultPageSize: selectDefTableSize(state),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Table));
