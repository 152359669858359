import React from 'react';
import { components } from 'react-select';

const AuditTypeOption = ({ children, value, ...props }) => {
    return (
        <components.Option {...props} value={value}>
            {
                value !== 1
                ? <i className="fas fa-gas-pump" />
                : <i className="fas fa-barrel" />
            }
            {' '}
            {children}
        </components.Option>
    )
}

export default AuditTypeOption;

