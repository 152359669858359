import React, { Component } from 'react';
import {renderError} from "../../../../services/helpers";
import validator from "../../../../services/DataValidator";
import api from "../../../../api";
import { hideModal, showModal } from "../../../../actions/modals";
import {connect} from "react-redux";
import DateTimePicker from "react-datetime-picker";
import Select from "../../../common/Select";
import contentDisposition from 'content-disposition'
import Spiner from "../../../common/Spiner";
import {toCompanyList} from "../../../../helpers/toLists";
let FileSaver = require('file-saver');

class LeftoversReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            closed: false,
            company: { value: 0, label: 'Всі' },
            date: '',
            companyList: [],
            validation: [],
            callback: () => {},
        };
    }

    async componentDidMount(){


        this.setState({
            companyList: [{ value: 0, label: 'Всі' }].concat(await api.apiGetCompanyList().then(toCompanyList)),
        })
    }

    handleDate = date => {
        this.setState({
            date,
        });
    };

    handleCompany = (selectedOption) => {
        this.setState({
            company: selectedOption
        });
    };

    handleSave = async () => {

        const { title, name } = this.props.propsData

        let validationResult = validator.arrayValidation([
            { id: 'company', name: 'Компанія', type: 'EMPTY', value: this.state.company },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let date = (new Date(this.state.date).toLocaleDateString("uk-UA")).split(',')[0];

            date = date.split('.');

            let dataToSend = {
                company: this.state.company.value,
                fromDate: date.indexOf('Invalid Date') === -1 ? `${date[2]}-${date[1]}-${date[0]}` : null,
                route: name
            };

            this.props.dispatchHideModal();
            this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" формується...', type: null })

            try {
                const res = await api.apiCreateLeftoversReport(dataToSend);
                this.setState({ loading: false });
                const fileName = contentDisposition.parse(res.headers['content-disposition']).parameters.filename;

                let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
                FileSaver.saveAs(blob, fileName);

                if(res.status == 200) {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" був успішно сформований.', type: true })
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" не був сформований. Зверніться до адміністратора', type: false })
                }
            } catch (e) {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" не був сформований. Зверніться до адміністратора', type: false })
            }
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){

        const { date, companyList } = this.state
        const { title } = this.props.propsData
        return (
            <div className="noncom-modal">
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">{ title }</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={companyList}
                                        placeholder="Виберіть компанію ..."
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <div className="df ai-center">
                                    <h5>Станом на</h5>
                                    <DateTimePicker
                                        locale="uk-UK"
                                        className="react-datetime-picker_margin-l-20"
                                        onChange={this.handleDate}
                                        value={date}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Створити</span>
                                        : <span>Створюємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftoversReport);
