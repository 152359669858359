import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { numberFormat } from "../../../../services/helpers";
import { selectDefTableSize } from '../../../../selectors/screenSelectors';
import CheckboxFilter from "../../../common/table/Checkbox";
import AuditTypeOption from './AuditTypeOption';
import PaymentTypeOption from './PaymentTypeOption';
import createSelect from '../../../common/table/createSelect';
import DatePicker from '../../../common/table/DatePicker';
import NumberInput from '../../../common/table/NumberInput';
import StringInput from '../../../common/table/StringInput';

const checkType = [
    { value: '1', label: 'З чеком' },
    { value: '2', label: 'Еко режим' }
];

const сompanyPaymentNameOptions = [
    { label: 'Безготівковий розрахунок', value: 'Безготівковий розрахунок'},
    { label: 'Готівковий розрахунок', value: 'Готівковий розрахунок'}
]

class AuditTable extends Component {

    constructor(props){
        super(props);
        this.state = {

        };
    }

    render(){
        const { defaultPageSize } = this.props;
        return (
            <div className={`table-main-wrap ${this.props.propsGetDataStatus == 'pending' ? 'table-main-wrap_loading' : '' }`}>
                <div className="table-header">Інформація про аудит</div>
                <ReactTable
                    pageSize={this.props.pageSize}
                    page={this.props.page}
                    onPageSizeChange={this.props.handleChangePageSize}
                    onPageChange={this.props.handleChangePage}
                    manual={true}
                    filterable
                    sortable
                    multiSort
                    onFilteredChange={this.props.handleOnFilterChange}
                    onSortedChange={this.props.handleOnSortChange}
                    filtered={this.props.filtered}
                    sorted={this.props.sorted}
                    pages={Math.ceil(this.props.count / this.props.pageSize)}
                    getTheadFilterThProps={(rowInfo) => {
                        return {
                            style: {
                                overflow: 'visible',
                                textAlign: 'left'
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.setState({
                                        selected: rowInfo.index
                                    })
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.state.selected ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.state.selected ? 'white' : 'black',
                                    cursor: 'pointer',
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={this.props.propsAuditArr}
                    
                    columns={[
                        {
                            Filter: createSelect({ options: this.props.data.auditTypeList, Option: AuditTypeOption, placeholder: 'Виберіть' }),
                            minWidth: 200,
                            Header: "Тип аудиту",
                            id: "TypeAuditFuelID",
                            Cell: (row) => {
                                if (!row.original.TypePayment) return (
                                    <div><i className="circle" /></div>
                                );
                                const { original: { TypePaymentID, TypeAuditFuelID } } = row;
                                return (
                                    <div className="audit-fuel-name">
                                        {
                                            TypeAuditFuelID !== 1
                                                ? <i className="circle" />
                                                : (
                                                    <i className={`${TypePaymentID === 1 ? 'blue' : 'green'} circle`} />
                                                )
                                        }
                                        {' '}
                                        {
                                            TypeAuditFuelID !== 1
                                                ? <i className="fas fa-gas-pump" />
                                                : <i className="fas fa-barrel" />
                                        }
                                    </div>
                                )
                            },
                            accessor: row => `${row.TypeAuditFuel.AuditFuelName}`,
                        },
                        {
                            Filter: createSelect({ options: this.props.data.fuelList, placeholder: 'Виберіть' }),
                            minWidth: 200,
                            Header: "Тип палива",
                            id: "Barrel/TypeFuelID",
                            accessor: row => `${row.Barrel.TypeFuel.FuelName}`,
                        },
                        {
                            Filter: StringInput,
                            Header: "Назва компанії",
                            id: "CompanyName",
                            accessor: row => `${row.CompanyName || '-----------'}`,
                        },
                        {
                            Filter: createSelect({ options: сompanyPaymentNameOptions, placeholder: 'Виберіть' }),
                            minWidth: 230,
                            Header: "Форма оплати компанії",
                            id: "CompanyPaymentName",
                            accessor: row => `${row.CompanyPaymentName || '-----------'}`,
                        },
                        {   
                            Filter: DatePicker,
                            minWidth: 170,
                            Header: "Дата операції",
                            id: "FuelDate",
                            accessor: item => {
                                return new Date(item.FuelDate).toLocaleString()
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 200,
                            Header: "Сума готівки, грн",
                            id: "AmountCash",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.AmountCash)
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 200,
                            Header: "Сума, грн",
                            id: "Amount",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.Amount)
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 200,
                            Header: "Ціна, грн",
                            id: "Price",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.Price)
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 200,
                            Header: "Об’єм, л",
                            id: "Capacity",
                            className: "rightAlign",
                            accessor: item => {
                                return numberFormat(item.Capacity)
                            }
                        },
                        {
                            Filter: createSelect({ options: checkType, placeholder: 'Виберіть' }),
                            minWidth: 155,
                            Header: "Номер чека",
                            id: "ReceiptNumber",
                            className: "rightAlign",
                            accessor: item => {
                                return item.ReceiptNumber ? item.ReceiptNumber : '--------------'
                            }
                        },
                        {
                            Filter: createSelect({ options: this.props.data.typePaymentList, Option: PaymentTypeOption, placeholder: 'Виберіть' }),
                            minWidth: 230,
                            Header: "Форма оплати",
                            id: "TypePaymentID",
                            accessor: item => {
                                return item.TypePayment != null ? item.TypePayment.PaymentName : '--------------'
                            }
                        },
                        {
                            Filter: CheckboxFilter,
                            minWidth: 150,
                            Header: "Автореєстрація",
                            id: "AutoRegister",
                            accessor: item => {
                                return <input type="checkbox" checked={item.AutoRegister ? true : false} readOnly/>
                            }
                        },
                        {
                            Filter: CheckboxFilter,
                            minWidth: 150,
                            Header: "Зареєстровано",
                            id: "Registered",
                            accessor: item => {
                                return <input type="checkbox" checked={item.Registered ? true : false} readOnly/>
                            }
                        },
                        {
                            Filter: NumberInput,
                            minWidth: 200,
                            Header: "Сума знижки",
                            id: "DiscountAmount",
                            className: "rightAlign",
                            accessor: item => {
                                return item.DiscountAmount ? numberFormat(item.DiscountAmount) : ''
                            }
                        },
                        {
                            Filter: createSelect({ options: this.props.data.discountTypeList, placeholder: 'Виберіть' }),
                            minWidth: 350,
                            Header: "Тип знижки",
                            id: "TypeDiscountID",
                            Cell: (row) => {
                                const { original: { DiscountName } } = row;
                                return (
                                    <div>{DiscountName || ''}</div>
                                )
                            },
                            accessor: row => `${row.DiscountName}`,
                        },
                    ]}
                    defaultPageSize={defaultPageSize}
                    className=" -highlight"
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                />
                <div className="loading"></div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    defaultPageSize: selectDefTableSize(state),
});

export default connect(mapStateToProps)(AuditTable);
