import * as Msal from '@azure/msal-browser';
import config from '../config/config.js';

const msalConfig = {
    auth: config.b2cAuth,
};

const tokenRequest = {
    scopes: config.b2cScopes,
};

export const myMSALObj = new Msal.PublicClientApplication(msalConfig);

export async function signIn() {
    try {
        const currentAccounts = myMSALObj.getAllAccounts();
        tokenRequest.account = currentAccounts[0];
        const tokenResponse = await myMSALObj.acquireTokenSilent(tokenRequest);
        return tokenResponse.accessToken
    } catch (error) {
        if (error.name !== 'BrowserAuthError') {
            console.error('Msal error', error)
            return
        }
        const token = await myMSALObj.handleRedirectPromise().then((tokenResponse) => {
            if (!tokenResponse) return;
            return tokenResponse.accessToken;
        }).catch((error) => {
            console.log('handleRedirectPromise', error);
        })
        if (token) return token;
        myMSALObj.loginRedirect(tokenRequest);
    }
}
