import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { hideModal } from '../../actions/modals';

import FuelComming from '../dashboard/fuelComing';

import CardModal from '../dashboard/cod/items/card/items/CardModal';
import CardEditModal from '../dashboard/cod/items/card/items/CardEditModal';
import CardDeleteModal from '../dashboard/cod/items/card/items/CardDeleteModal';

import CouponModal from '../dashboard/cod/items/coupon/items/CouponModal';
import CouponEditModal from '../dashboard/cod/items/coupon/items/CouponEditModal';
import CouponDeleteModal from '../dashboard/cod/items/coupon/items/CouponDeleteModal';
import CouponDeleteAllModal from '../dashboard/cod/items/coupon/items/CouponDeleteAllModal';
import CouponLoadModal from '../dashboard/cod/items/coupon/items/CouponLoadModal';

import PriceModal from '../dashboard/cod/items/price/items/PriceModal';
import PriceEditModal from '../dashboard/cod/items/price/items/PriceEditModal';
import PriceDeleteModal from '../dashboard/cod/items/price/items/PriceDeleteModal';

import CompanyModal from "../dashboard/cod/items/company/items/CompanyModal";

import CompanyEditModal from "../dashboard/cod/items/company/items/CompanyEditModal";
import CompanyDeleteModal from "../dashboard/cod/items/company/items/CompanyDeleteModal";

import DepartmentCreateModal from "../dashboard/cod/items/department/items/DepartmentCreateModal";
import DepartmentEditModal from "../dashboard/cod/items/department/items/DepartmentEditModal";
import DepartmentDeleteModal from "../dashboard/cod/items/department/items/DepartmentDeleteModal";

import EmployeeCreateModal from "../dashboard/cod/items/employee/items/EmployeeCreateModal";
import EmployeeEditModal from "../dashboard/cod/items/employee/items/EmployeeEditModal";
import EmployeeDeleteModal from "../dashboard/cod/items/employee/items/EmployeeDeleteModal";

import RegisterCreateModal from "../dashboard/cod/items/register/items/RegisterCreateModal";
import RegisterEditModal from "../dashboard/cod/items/register/items/RegisterEditModal";
import RegisterDeleteModal from "../dashboard/cod/items/register/items/RegisterDeleteModal";

import LoanCreateModal from "../dashboard/cod/items/register/items/LoanCreateModal";
import LoanDeleteModal from "../dashboard/cod/items/register/items/LoanDeleteModal";

import CompanyMovementCreateModal from "../dashboard/cod/items/companyMovement/items/CompanyMovementCreateModal";
import CompanyMovementEditModal from "../dashboard/cod/items/companyMovement/items/CompanyMovementEditModal";
import CompanyMovementDeleteModal from "../dashboard/cod/items/companyMovement/items/CompanyMovementDeleteModal";

import RegisterAuditCreateModal from "../dashboard/cod/items/registerAudit/items/RegisterAuditCreateModal";
import RegisterAuditEditModal from "../dashboard/cod/items/registerAudit/items/RegisterAuditEditModal";
import RegisterAuditDeleteModal from "../dashboard/cod/items/registerAudit/items/RegisterAuditDeleteModal";

import NoncommercialcardReport from "../dashboard/reports/items/NoncommercialcardReport";
import PeriodicReport from "../dashboard/reports/items/PeriodicReport";
import LeftoversReport from "../dashboard/reports/items/LeftoversReport";
import StationtotalReport from "../dashboard/reports/items/StationtotalReport";
import AggregatedCardReport from "../dashboard/reports/items/AggregatedCardReport";
import CashReport from "../dashboard/reports/items/CashReport";
import BarrelBalanceReport from "../dashboard/reports/items/BarrelBalanceReport";
import LostChecksReport from "../dashboard/reports/items/LostChecksReport";
import UncoveredReport from "../dashboard/reports/items/UncoveredReport";
import ProfitTransactionsReport from '../dashboard/reports/items/ProfitTransactionsReport';
import CompanyRegistersReport from "../dashboard/reports/items/CompanyRegistersReport";
import ClientCardOperationsReport from '../dashboard/reports/items/ClientCardOperationsReport';

import OwnerModal from '../dashboard/owners/items/OwnerModal';
import OwnerEditModal from '../dashboard/owners/items/OwnerEditModal';
import OwnerDeleteModal from '../dashboard/owners/items/OwnerDeleteModal';
import OwnerSynchronizationModal from '../dashboard/owners/items/OwnerSynchronizationModal';

import StationModal from '../dashboard/petrolStations/items/StationModal';
import StationEditModal from '../dashboard/petrolStations/items/StationEditModal';
import StationDeleteModal from '../dashboard/petrolStations/items/StationDeleteModal';

import PetrolBoxClientModal from '../dashboard/administration/petrolBoxClient/items/PetrolBoxClientModal';
import PetrolBoxClientEditModal from '../dashboard/administration/petrolBoxClient/items/PetrolBoxClientEditModal';
import PetrolBoxClientDeleteModal from '../dashboard/administration/petrolBoxClient/items/PetrolBoxClientDeleteModal';

import TRKEditModal from "../dashboard/trk/items/EditModal";

import BarrelEditModal from "../dashboard/barrel/items/EditModal";

import InfoModal from "./InfoModal";

const ModalsByTypes = {
    PERIODIC_REPORT_MODAL: PeriodicReport,
    NONCOMMERCIALCARD_REPORT_MODAL: NoncommercialcardReport,
    LEFTOVERS_REPORT_MODAL: LeftoversReport,
    STATIONTOTAL_REPORT_MODAL: StationtotalReport,
    AGGREGATEDCARD_REPORT_MODAL: AggregatedCardReport,
    COMPANYREGISTERS_REPORT_MODAL: CompanyRegistersReport,
    UNCOVERED_REPORT_MODAL: UncoveredReport,
    CASH_REPORT_MODAL: CashReport,
    BARRELBALANCE_REPORT_MODAL: BarrelBalanceReport,
    WRONGLEVEL_REPORT_MODAL: LostChecksReport,
    PROFITTRANSACTIONS_REPORT_MODAL: ProfitTransactionsReport,
    CLIENTCARDOPERATIONS_REPORT_MODAL : ClientCardOperationsReport,
    FUEL_COMING_MODAL: FuelComming,
    CARD_MODAL: CardModal,
    CARD_EDIT_MODAL: CardEditModal,
    CARD_DELETE_MODAL: CardDeleteModal,
    COUPON_MODAL: CouponModal,
    COUPON_LOAD_MODAL: CouponLoadModal,
    COUPON_EDIT_MODAL: CouponEditModal,
    COUPON_DELETE_MODAL: CouponDeleteModal,
    COUPON_DELETE_ALL_MODAL: CouponDeleteAllModal,
    PRICE_MODAL: PriceModal,
    PRICE_EDIT_MODAL: PriceEditModal,
    PRICE_DELETE_MODAL: PriceDeleteModal,
    COMPANY_MODAL: CompanyModal,
    COMPANY_EDIT_MODAL: CompanyEditModal,
    COMPANY_DELETE_MODAL: CompanyDeleteModal,
    DEPARTMENT_CREATE_MODAL: DepartmentCreateModal,
    DEPARTMENT_EDIT_MODAL: DepartmentEditModal,
    DEPARTMENT_DELETE_MODAL: DepartmentDeleteModal,
    EMPLOYEE_CREATE_MODAL: EmployeeCreateModal,
    EMPLOYEE_EDIT_MODAL: EmployeeEditModal,
    EMPLOYEE_DELETE_MODAL: EmployeeDeleteModal,
    REGISTER_CREATE_MODAL: RegisterCreateModal,
    REGISTER_EDIT_MODAL: RegisterEditModal,
    REGISTER_DELETE_MODAL: RegisterDeleteModal,
    LOAN_CREATE_MODAL: LoanCreateModal,
    LOAN_DELETE_MODAL: LoanDeleteModal,
    COMPANY_MOVEMENT_CREATE_MODAL: CompanyMovementCreateModal,
    COMPANY_MOVEMENT_EDIT_MODAL: CompanyMovementEditModal,
    COMPANY_MOVEMENT_DELETE_MODAL: CompanyMovementDeleteModal,
    REGISTER_AUDIT_CREATE_MODAL: RegisterAuditCreateModal,
    REGISTER_AUDIT_EDIT_MODAL: RegisterAuditEditModal,
    REGISTER_AUDIT_DELETE_MODAL: RegisterAuditDeleteModal,
    TRK_EDIT_MODAL: TRKEditModal,
    BARREL_EDIT_MODAL: BarrelEditModal,
    OWNER_MODAL: OwnerModal,
    OWNER_EDIT_MODAL:  OwnerEditModal,
    OWNER_DELETE_MODAL: OwnerDeleteModal,
    OWNER_SYNCHRONIZATION_MODAL: OwnerSynchronizationModal,
    STATION_MODAL: StationModal,
    STATION_EDIT_MODAL: StationEditModal,
    STATION_DELETE_MODAL: StationDeleteModal,
    PETROLBOX_CLIENT_MODAL: PetrolBoxClientModal,
    PETROLBOX_CLIENT_EDIT_MODAL: PetrolBoxClientEditModal,
    PETROLBOX_CLIENT_DELETE_MODAL: PetrolBoxClientDeleteModal,
    INFO_MODAL: InfoModal,
}

const Modals = ({ show, hideModalProps, content }) => {
    const ModalBodyComponent = ModalsByTypes[content.type];
    if (!ModalBodyComponent) return (<div/>);
    return (
      <Modal show={show} onHide={hideModalProps}>
          <ModalBodyComponent propsData={content.data} closeModal={hideModalProps} />
      </Modal>
    )
}

const mapStateToProps = (state) => {
    return{
        show: state.modals.show,
        content: state.modals.content
    }
};

const mapDispatchToProps = (dispatch) => {
  return{
      hideModalProps: () => dispatch(hideModal())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Modals);

