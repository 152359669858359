import React, { Component } from 'react';
import { connect } from 'react-redux';

import Wrapper from '../index';
import Sidebar from '../cod/Sidebar';

import api from '../../../api';
import StationTable from './items/StationTable';
import { showModal } from "../../../actions/modals";
import {toPascalCase} from "../../../services/helpers";
import getCount from "../../../helpers/getCount";
import sortConverter from "../../../helpers/sortConverter";
import { stringConverter, createFilter, numberConverter } from "../../../helpers/filterConverters";
import { setFilterData } from '../../../actions/filtersReducers';

const convertersMap = {
    Name: stringConverter,
    'Addresses/Streat': stringConverter,
    'Addresses/City/ID': numberConverter,
    'Addresses/City/Name': stringConverter,
    'ID': numberConverter,
} 

class Index extends Component{

    constructor(props){
        super(props);
        this.state = {
            count: 0,
            pageSize: 10,
            page: 0,
            rowID: null,
            index: null,
            filtered: [],
            sorted: [],
            bocksesList: [],
            sortedStationArray: [],
            getDataStatus: 'success',
            stationArray: [],
            filter: false
        };
    }

    static filterType = 'STATION'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.filtered, Index.filterType));
    }
    async componentDidMount(){
        this.setState({
            filtered: this.props.filtersReducer[Index.filterType] || this.state.filtered,
        }, () => {
            this.handleGetData();
        });
    }

    handleOnFilterChange = (filtered = []) => {
        this.setState({ filtered }, this.handleGetData)
    }

    handleOnSortChange = (sorted = []) => {
        this.setState({ sorted }, this.handleGetData);
    }

    handleRowClick = (data) => {
        this.setState({
            rowID: data.id,
            index: data.index
        });
    };

    findObject = () => {
        let tmpObj = {};
        for(let i = 0; i < this.state.stationArray.length; i++){
            if(this.state.rowID == this.state.stationArray[i].ID){
                tmpObj = Object.assign({}, this.state.stationArray[i]);
            }
        }
        return tmpObj;
    };

    handleCreate = () => {
        let tmpObj = {};
        tmpObj.callback = this.handleGetData;
        this.props.dispatchShowModal('STATION_MODAL', tmpObj)
    };

    handleEdit = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleGetData;
        this.props.dispatchShowModal('STATION_EDIT_MODAL', tmpObj);
    };

    handleDelete = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleGetData;
        this.props.dispatchShowModal('STATION_DELETE_MODAL', tmpObj);
    };

    handleClearFilter = async () => {
        this.setState({
            page: 0,
            filtered: [],
            sortedStationArray: []
        }, this.handleGetData);
    };

    handleGetData = async () => {
        this.setState({
            getDataStatus: 'pending'
        });

        const data = {
            top: this.state.pageSize,
            skip: this.state.pageSize * this.state.page,
            filter: createFilter(this.state.filtered, convertersMap),
            order: sortConverter(this.state.sorted),
        };
        
        const stationRes = await api.apiGetStationData(data);
        const stationArray = toPascalCase(stationRes.data.value);

        this.setState({
            stationArray,
            getDataStatus: 'success',
            count: getCount(stationRes),
        });
    }

    handleChangePageSize = (pageSize) => {
        this.setState({ page: 0, pageSize }, () => this.handleGetData())
    };

    handleChangePage = (page) => {
        this.setState({ page }, () => this.handleGetData())
    };

    handleSortChange =(page) => {
        this.setState({})
    };

    render(){
        return (
            <Wrapper>
                <div className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <StationTable
                                propsGetDataStatus={this.state.getDataStatus}
                                handleChangePageSize={this.handleChangePageSize}
                                handleChangePage={this.handleChangePage}
                                count={this.state.count}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                propsRowID={this.state.rowID}
                                propsRowClick={this.handleRowClick}
                                propsArray={this.state.stationArray}
                                data={this.state.data}
                                index={this.state.index}
                                createClearField={this.createClearField}
                                handleOnFilterChange={this.handleOnFilterChange}
                                handleOnSortChange={this.handleOnSortChange}
                                filtered={this.state.filtered}
                                sorted={this.state.sorted}
                            />
                        </div>
                    </div>
                    <Sidebar
                        propsOnCreate={() => this.handleCreate()}
                        propsOnEdit={() => this.handleEdit()}
                        propsOnDelete={() => this.handleDelete()}
                        propsDisableEdit={ this.state.rowID == null ? true : false }
                        propsDisableDelete={ this.state.rowID == null ? true : false }
                        onClear={this.handleClearFilter}
                        propsSelected={{stationsMovement: true}}
                        showAdministration={ true }
                    />
                </div>
            </Wrapper>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);