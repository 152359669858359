import React, { Component } from 'react';
import Select from 'react-select';
import DateTimePicker from "react-datetime-picker";
import api from "../../../api";
import {normalizeNumberMaxDecimals, renderError} from "../../../services/helpers";
import validator from "../../../services/DataValidator";
import {showModal, hideModal} from "../../../actions/modals";
import {connect} from "react-redux";
import { selectStation } from "../../../selectors/stationSelectors";
import Spiner from "../../common/Spiner";
import {toBarrelFuelList} from "../../../helpers/toLists";

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedFuelType: '',
            docNumber: '',
            date: new Date(),
            volume: '',
            ekkr: false,
            price: '',
            payType: '',
            typePaymentList: [
                { value: 1, label: 'Відомість Д' },
                { value: 0, label: 'Готівковий розрахунок' },
            ],
            fuelList: [],
            validation: []
        };
    }

    async componentDidMount() {
        const date = new Date().toLocaleString();

        let docNumber = 'ВП';

        const part1Pattern = new RegExp('/', 'g')
        const part2Pattern = new RegExp(':', 'g')

        let part1 = date.split(',')[0];
        let part2 = date.split(',')[1];

        part1 = part1.replace(part1Pattern, '')
        part2 = part2.replace(part2Pattern, '')

        docNumber = docNumber + part1 + part2

        docNumber = docNumber.replace(' ', '')

        this.setState({
            docNumber,
            fuelList: await api.apiGetBarrelData().then(toBarrelFuelList),
        })
    }

    handleChangeFuel = (selectedOption) => {
        this.setState({
            selectedFuelType: selectedOption
        });
    };

    handleChangeDate = date => this.setState({ date });

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleChangePayType = (selectedOption) => {
        this.setState({
            payType: selectedOption,
            ekkr: selectedOption.value === 1
        });
    };

    handleSave = () => {
        let validationResult = validator.arrayValidation([
            { id: 'fuelType', name: 'Тип палива', type: 'EMPTY', value: this.state.selectedFuelType },
            { id: 'docNumber', name: 'Номер документу', type: 'EMPTY', value: this.state.docNumber },
            { id: 'date', name: 'Дата', type: 'EMPTY', value: this.state.date },
            { id: 'volume', name: 'Об`єм', type: 'EMPTY', value: this.state.volume },
            { id: 'price', name: 'Ціна, л', type: 'EMPTY', value: this.state.price },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                BarrelID: +this.state.selectedFuelType.value,
                DocNumber: this.state.docNumber,
                FuelDate: this.state.date,
                Capacity: +this.state.volume,
                Price: +this.state.price,
                AmountCash: 0,
                Amount: 0,
                TypeAuditFuelID: 1,
                TypePaymentID: +this.state.payType.value,
                // barrelID: 1,
                AutoRegister: this.state.ekkr,
                // Registered: !this.state.ekkr,
            };
            api.apiCreateFuelUncoming(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 201){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Відгрузка палива', description: 'Відгрузка палива була успішно додана', type: true });
                    this.props.propsData.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Відгрузка палива', description: 'Відгрузка палива не створена. Зверніться до адміністратора.', type: false });
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Відгрузка палива', description: error.response.data.message, type: false });
            });

        }

        this.setState({
            validation: validationResult.errorArr
        });

    };

    render(){
        const { station } = this.props;
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Відгрузка палива AЗС {station.City.Name + ', ' + station.Street}</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Тип палива</h5>
                                <Select
                                    placeholder="Виберіть тип палива"
                                    value={this.state.selectedFuelType}
                                    onChange={this.handleChangeFuel}
                                    options={this.state.fuelList}
                                />
                            </div>
                            <div className="form-group-error">
                                { renderError('fuelType', this.state.validation) }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Номер документу</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть номер документу"
                                        type="text"
                                        className="form-control"
                                        name="docNumber"
                                        value={this.state.docNumber}

                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        {renderError('docNumber', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Дата внесення</h5>
                                <DateTimePicker
                                    disabled={true}
                                    locale="uk-UK"
                                    onChange={this.handleChangeDate}
                                    value={this.state.date}
                                />
                                <div className="form-group-error">
                                    {renderError('date', this.state.validation)}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Об'єм, л.</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть об'єм"
                                        type="text"
                                        className="form-control"

                                        data-type="number"
                                        name="volume"

                                        value={this.state.volume}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        {renderError('volume', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ціна, грн</h5>
                                <div className="form-group">
                                    <input
                                      placeholder="Введіть ціну"
                                      type="text"
                                      className="form-control"
                                      name="price"
                                      data-type="number"
                                      value={this.state.price}
                                      onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('price', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Форма оплати</h5>
                                <Select
                                  placeholder="Виберіть форму оплати"
                                  value={this.state.payType}
                                  onChange={this.handleChangePayType}
                                  options={this.state.typePaymentList}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Відправити до ЕККР</h5>
                                <div className="checkbox">
                                    <label>
                                        <input
                                            disabled={this.state.payType}
                                            type="checkbox"
                                            name="ekkr"
                                            checked={this.state.ekkr}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Створити</span>
                                        : <span>Створюємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        station: selectStation(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
