import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime-picker'

import {normalizeNumberMaxDecimals, renderError} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import api from "../../../../../../api";
import { hideModal, showModal } from "../../../../../../actions/modals";
import Spiner from "../../../../../common/Spiner";

class LoanCreateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            date: new Date(),
            amount: '',
            callback: () => {},
            validation: []
        };
    }

    handleChangeDate = date => this.setState({ date });

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type === 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleSave = () => {
        let validationResult = validator.arrayValidation([
            { id: 'amount', name: 'amount', type: 'EMPTY', value: this.state.amount },
        ]);

        if(validationResult.state === 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                Amount: +this.state.amount,
                CoverageDate: this.state.date,
                RegisterID: this.props.propsData.registerId,
            };

            api.apiCreateLoan(dataToSend).then(res => {
                this.setState({ loading: false });
                if(Number(res.status) === 201){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Додати погашення', description: 'Погашення було успішно створене', type: true })
                    this.props.propsData.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Додати погашення', description: 'Погашення не було створено. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Додати погашення', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Додати погашення</h5>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Дата погашення</h5>
                                <div>
                                    <DateTimePicker
                                        disabled={true}
                                        locale="uk-UK"
                                        onChange={this.handleChangeDate}
                                        value={this.state.date}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Сума погашення</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть суму погашення"
                                        type="text"
                                        className="form-control"
                                        name="amount"
                                        data-type="number"

                                        value={this.state.amount}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('amount', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                                disabled={this.state.loading}
                            >
                                {
                                    !this.state.loading
                                    ? <span>Зберегти</span>
                                    : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(null, mapDispatchToProps)(LoanCreateModal);
